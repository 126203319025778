import { useContext } from 'react'
import { MedBotsContext } from '../../context'
import './View.scss'
import { MainContext } from 'context'
import { useParams } from 'react-router-dom'

export const MEDBOT_DETAIL_KEYS = ['medbot_details']

export const useMedbotDetails = () => {
  const { medbotId } = useParams()
  const { admin } = useContext(MainContext)
  const { actions, hasEdits } = useContext(MedBotsContext)

  return admin.api.createEditMedbots.getMedBotDetails({
    variables: { medbotId },
    keys: MEDBOT_DETAIL_KEYS,
    queryOptions: {
      staleTime: 0,
      enabled: !!medbotId && !hasEdits,
      onSuccess: data => {
        actions.viewMedBot({
          lineItems: data?.localLineItems,
          medbotId: data?.localId,
          ...data,
        })
      },
    },
  })
}
