import { StripeLogo } from 'assets/vector'

export const StripeFooter = () => {
  return (
    <div className="stripe-footer-wrap">
      <a href="http://www.stripe.com/" rel="noreferrer" target="_blank">
        <StripeLogo id="stripe-logo" />
      </a>
      <div className="terms-privacy">
        <a href="https://stripe.com/legal/ssa" rel="noreferrer" target="_blank">
          Terms
        </a>
        <a href="https://stripe.com/privacy" rel="noreferrer" target="_blank">
          Privacy
        </a>
      </div>
    </div>
  )
}
