import { createContext } from 'react'
import { useImmerReducer } from 'use-immer'
import {
  adminInit,
  AdminReducer,
  modalInit,
  ModalReducer,
  patientInit,
  PatientReducer,
  useAdminActions,
  useModalActions,
  usePatientActions,
} from 'context/Reducers'

const adminStored = sessionStorage?.getItem?.('medtrix_admin_login')

const initialState = {
  admin: adminStored ? JSON?.parse?.(adminStored) : adminInit,
  modal: modalInit,
  patient: patientInit,
}

export const MainContext = createContext(initialState)

export const MainProvider = ({ children }) => {
  const [patientState, patientDispatch] = useImmerReducer(PatientReducer, patientInit)
  const patientActions = usePatientActions(patientDispatch)

  const [modalState, modalDispatch] = useImmerReducer(ModalReducer, modalInit)
  const modalActions = useModalActions(modalDispatch)

  const [adminState, adminDispatch] = useImmerReducer(AdminReducer, adminInit)
  const adminActions = useAdminActions(adminDispatch, adminState?.authentication, modalActions)

  return (
    <MainContext.Provider
      value={{
        admin: { ...adminState, ...adminActions },
        modal: { ...modalState, ...modalActions },
        patient: { ...patientState, ...patientActions },
      }}
    >
      {children}
    </MainContext.Provider>
  )
}
