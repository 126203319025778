import { defaultProductImg } from 'assets/raster'
import { BlueRightArrow } from 'assets/vector'
import { Button } from 'components'
import { DoctorMessage } from 'pages/Patient'
import { useCallback } from 'react'
import './ProductsInOrder.scss'

/*{ 
  products, 
  onAddMore?, /// when they click the "add more products", no onAddMore will hide button
  onSelect, // when they choose a product
  provider, 
  label?, 
  useEditButton?: boolean
} = props
*/
export const ProductsInOrder = ({ products, onAddMore, label = 'Products For Order', ...rest }) => {
  const mapProducts = useCallback(
    () =>
      products?.length ? (
        products?.map((p, i) => <Product key={i} product={p} {...rest} />)
      ) : (
        <span className="products-in-order__no-products-notice">There are no products in your order.</span>
      ),
    [products, rest]
  )

  return (
    <div className="products-in-order">
      <span className="products-in-order__label">{label}</span>
      {mapProducts()}
      {!!onAddMore && (
        <Button onClick={() => onAddMore()} className={'products-in-order__add-another-button'}>
          Add {products?.length ? 'Another' : 'A'} Product
        </Button>
      )}
    </div>
  )
}

//because sometimes we want the whole item to be a button and sometimes a button inside the item
const Product = ({ useEditButton = false, onSelect, product, ...rest }) =>
  useEditButton ? (
    <div style={{ cursor: 'unset' }} className="products-in-order__item">
      <ProductWrap product={product} {...rest}>
        <EditButton onClick={() => onSelect(product)} />
      </ProductWrap>
    </div>
  ) : (
    <button onClick={() => onSelect(product)} className="products-in-order__item">
      <ProductWrap product={product} {...rest} />
    </button>
  )

const ProductWrap = ({ product, provider = null, children }) => (
  <>
    <div className="products-in-order__item--content">
      {!children && <BlueRightArrow className="right-arrow" />}
      <div className="products-in-order__item--content-img">
        <img
          alt="product"
          onError={({ currentTarget }) => (currentTarget.src = defaultProductImg)}
          src={product?.image?.[0]?.src || product?.productImages?.[0]}
        />
      </div>
      <div className="products-in-order__item--content-info">
        <span className="products-in-order__item--content-info--name">{product?.productName || '--'}</span>
        <span className="products-in-order__item--content-info--quantity">
          <p className="qty">Qty</p>
          <p className="qty-amount">{product?.quantity || '--'}</p>
        </span>
      </div>
      {children}
    </div>
    {product?.doctorsMessage && (
      <DoctorMessage
        msg={product?.doctorsMessage}
        avatar={provider?.avatarUrl}
        lastInitial={provider?.name?.split(' ')[1][0]}
        title={'Message from your Doctor'}
      />
    )}
  </>
)

const EditButton = ({ onClick }) => (
  <Button onClick={onClick} className={'edit-button'}>
    Edit MedBot Details{' '}
  </Button>
)
