import { useCallback, useContext } from 'react'
import { CreateOrderContext } from '../../context'
import './SelectPatient.scss'
import { PaginatedPatientList } from 'components/AlphabeticList'

export const SelectPatient = () => {
  const { actions, provider, sortListBy } = useContext(CreateOrderContext)

  const onSelectPatient = useCallback(patient => actions.setPatient(patient), [actions])

  return (
    <>
      {/* <UpgradeEmr show={cannotConnect && !isLoading && !error} /> */}
      <PaginatedPatientList
        initSearchKeyword={sortListBy.keyword}
        onSelectPatient={onSelectPatient}
        practiceId={provider?.practiceId}
      />
    </>
  )
}

// const UpgradeEmr = ({ show }) => {
//   const { actions } = useContext(CreateOrderContext)
//   if (!show) return null
//   return (
//     <div className="upgrade-emr-overlay">
//       <div className="upgrade-emr-overlay__no-connect">
//         <span>We're sorry, we can't </span>
//         <span>connect to your EMR.</span>
//       </div>
//       <div className="upgrade-emr-overlay__graphics">
//         <Lottie className="animated-background" animationData={animatedBackgroundLottieData} play loop />
//         <SadMedbotIcon />
//       </div>
//       <div className="upgrade-emr-overlay__please-upgrade">
//         <span>Please upgrade your MedBot</span>
//         <span>account to access this feature.</span>
//       </div>
//       <Button className={'upgrade-emr-overlay__back-button'} onClick={() => actions.setPage(pages.REVIEW)}>
//         Back
//       </Button>
//     </div>
//   )
// }
