import { useCallback, useContext } from 'react'
import { CreateOrderContext } from '../context'
import { ProductView } from 'components/ProductView/ProductView'
import { pages } from '../constants'

export const ProductReview = () => {
  const { selectProduct, products, actions } = useContext(CreateOrderContext)
  const { product } = selectProduct

  const onUpdate = product => {
    actions.setProducts(products.map(p => (p.productId === product.productId ? product : p)))
    actions.setPage(pages.REVIEW)
  }

  const onDelete = useCallback(() => {
    actions.setProducts([...products.filter(p => p.productId !== product.productId)])
    actions.setPage(pages.REVIEW)
  }, [actions, product, products])

  return (
    <ProductView
      isInOrder
      product={selectProduct.product}
      onAddAnother={null} //disables onAdd another
      onUpdate={onUpdate}
      onDelete={onDelete}
    />
  )
}
