import { useMemo } from 'react'

const keys = {
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  RESET_MODAL: 'RESET_MODAL',
}

export const modalInit = {
  isOpen: false,
  modal: null,
  isClosing: false,
  actions: {},
  api: {},
}

export const ModalReducer = (draft, { type, payload }) => {
  switch (type) {
    case keys.OPEN_MODAL:
      draft.isOpen = true
      return void (draft.modal = payload)
    case keys.CLOSE_MODAL:
      return void (draft.isClosing = true)
    case keys.RESET_MODAL:
      return modalInit
    default:
      return draft
  }
}

export const useModalActions = dispatch =>
  useMemo(
    () => ({
      actions: {
        open: modal => {
          dispatch({ type: keys.OPEN_MODAL, payload: modal })
        },
        close: () => {
          dispatch({ type: keys.CLOSE_MODAL })
        },
        reset: () => {
          dispatch({ type: keys.RESET_MODAL })
        },
      },
    }),
    [dispatch]
  )
