import { AlertModal } from 'components'
import { MainContext } from 'context'
import { useCallback, useContext, useMemo } from 'react'

export const useProductControls = () => {
  const { patient, modal } = useContext(MainContext)

  const handleDelete = useCallback(
    product => {
      const data = patient.order
      const LineItems = data?.LineItems || data?.lineItems || data?.localLineItems
      if (LineItems?.length <= 1) {
        modal.actions.open(<AlertModal message="This is the only item in your order, so it can't be removed" />)
      } else {
        modal.actions.open(
          <AlertModal
            message={'Are you sure you want to remove this product from the order?'}
            denyText={'No'}
            confirmText={'Yes'}
            onConfirm={() => {
              patient.actions.removeItem(product)
              modal.actions.close()
            }}
          />
        )
      }
    },
    [patient, modal]
  )

  const handleToggle = useCallback(
    add => product => {
      const { quantity } = product
      if (!add && quantity <= 1) return
      const q = add ? quantity + 1 : quantity - 1
      patient.actions.editItem({ ...product, quantity: q })
    },
    [patient]
  )

  const onChangeRefillFrequency = useCallback(
    (newFrequency, product) => patient.actions.editItem({ ...product, refillFrequency: newFrequency }),
    [patient?.actions]
  )

  return useMemo(
    () => ({
      onChangeRefillFrequency,
      handleDelete,
      handleAdd: handleToggle(true),
      handleMinus: handleToggle(false),
    }),
    [onChangeRefillFrequency, handleDelete, handleToggle]
  )
}
