import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParamsToObject } from 'utilities/hooks'
import {  MedBotDarkBlue } from 'assets/vector'
import { Form } from 'react-final-form'
import { Input, Button } from 'components/Global'
import { AlertIcon } from 'assets/vector'
import './SetPassword.scss'
import { MainContext } from 'context'
import { ModalContainer, SuccessModal } from 'components'
import { passwordValidation } from 'utilities/utils'

export const SetPassword = ({ create }) => {
  const { admin, modal } = useContext(MainContext)
  const nav = useNavigate()
  const { email: urlEmail, token } = useParamsToObject()
  const [err, setErr] = useState(null)

  const { mutate: setPassword, isLoading } = admin.api.password.setPassword({
    queryOptions: {
      onSuccess: () => {
        nav('/admin', { replace: true })
        modal.actions.open(<SuccessModal title={`Password has been ${create ? 'created' : 'reset'}!`} />)
      },
      onError: error =>
        error.toString().includes('Error: ') ? setErr(error.toString().slice(7)) : setErr('Something went wrong.'),
    },
  })

  const onSubmit = ({ password, password2, email }) => {
    if (password !== password2) {
      setErr('passwords do not match')
      return
    } else setErr(null)

    setPassword({ body: { newPassword: password, email: email || urlEmail, token } })
  }

  // let validator = [
  //   v => (!/(?=.*\d)/.test(v) ? 'Password must contain one number' : null),
  //   v => (!/(?=.*[A-Z])/.test(v) ? 'Password must have a capital letter' : null),
  //   v => (!/(?=.*?[#?!@$%^&*-])/.test(v) ? 'Password must have a special character' : null),
  //   v => (v.length < 8 ? 'Password must be at least 8 characters long' : null),
  // ]

  return (
    <main className="login admin">
      <ModalContainer isAdmin />
      <Form
        onSubmit={onSubmit}
        initialValues={{ email: urlEmail }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="login-form set-password admin">
            <MedBotDarkBlue className="medtrix-logo-horizontal-admin" />
            <h2>{create ? 'Create A Password' : 'Reset Password'}</h2>
            {create && <Input className="email" label="Email" type="text" name="email" />}
            <p>
              Passwords should contain a minimum of 8 characters, using a mix of uppercase and lowercase letters,
              numbers, and special characters.
            </p>
            <Input validate={passwordValidation} label="New Password" type="password" name="password" required={true} />
            <Input
              validate={passwordValidation}
              label="Retype New Password"
              type="password"
              name="password2"
              required={true}
            />
            <Button type="submit">{isLoading ? 'Loading...' : 'Save Password'}</Button>
            <div className="errors">
              {err && (
                <div className="set-password-error">
                  <AlertIcon />
                  <span>{err}</span>
                </div>
              )}
            </div>
          </form>
        )}
      />
    </main>
  )
}

/*
  render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="login-form reset-password admin">
            <MedtrixHorizontal className="medtrix-logo-horizontal-admin" />
            <h2></h2>
            <p>
              Passwords should contain a minimum of 8 characters, using a mix of uppercase and lowercase letters,
              numbers, and special characters.
            </p>
            <Input label="New Password" type="password" />
            <Input label="Retype New Password" type="password" />
            {err && (
              <p className="password__warning">
                <AlertIcon className="field-error__alert" />
                {err}
              </p>
            )}
            <Button type="submit">Save Password</Button>
          </form>

*/
