import { ChevronRightIcon } from 'assets/vector'
import { MainContext } from 'context'
import { useContext, useState, useRef, useEffect, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import './FilterByBrand.scss'

export const FilterByBrand = ({ selectBrand, brand }) => {
  const ref = useRef()
  const [showList, setShowList] = useState(false)
  const { data, isLoading, error } = useBrands()

  useEffect(() => {
    const handleClick = e => {
      if (!ref?.current?.contains(e.target)) setShowList(false)
    }
    document.addEventListener('click', handleClick, true)
    return () => document.removeEventListener('click', handleClick, true)
  }, [setShowList])

  const mapBrands = brands => {
    if (isLoading) return <li>Loading...</li>
    if (error) return <li>There was an error.</li>

    return brands?.map((brand, i) => (
      <li
        onClick={() => {
          selectBrand(brand)
          setShowList(false)
        }}
        key={i}
      >
        {brand}
      </li>
    ))
  }
  return (
    <div ref={ref} onClick={() => setShowList(!showList)} className="filter-by-brand-wrap">
      <button className="filter-by-brand-button">
        <span className="filter-by-brand-label">{brand || 'FILTER BY BRAND'}</span>
        <ChevronRightIcon className="dropdown-arrow" />
      </button>
      <div className={`filter-by-brand-list ${showList ? 'show' : 'hide'}`}>
        <ul>
          <li
            onClick={() => {
              setShowList(false)
              selectBrand('')
            }}
          >
            CLEAR
          </li>
          {mapBrands(data)}
        </ul>
      </div>
    </div>
  )
}

const keys = ['product_brands']

const useBrands = () => {
  const qc = useQueryClient()
  const { admin } = useContext(MainContext)
  const isAdmin = useMemo(
    () => admin?.user?.role?.includes?.('admin') || admin?.user?.role === 'admin',
    [admin?.user?.role]
  )
  const apiFn = isAdmin ? admin.api.products.brands.forAdmin : admin.api.products.brands.forPractice

  useEffect(() => () => qc.cancelQueries(keys), [qc])

  return apiFn({
    variables: isAdmin ? null : { practiceId: admin?.user?.practiceId },
    keys,
  })
}
