import { useFetch } from 'utilities'

export const useAnalytics = (token, unauthorized) => ({
  /**
   * @example
   * getAnalytics['today' | 'week' | 'month' | 'year']({
   *    params: {
   *        practiceId: 0,
   *        page: 1,
   *        pagesize: 10
   *    }
   * })
   * @returns
   * {
   *    {...timeFrameSpecificData} (see below)
   *    totalRevenueYTD: 224.8000,
   *    revenueDifferenceToLastYTD: -48.5000,
   *    productsSold: 4,
   *    ordersPurchased: 2,
   *    medBotsCreated: 32,
   *    activePhysicians: 57,
   *    totalRows: 57,
   *    page: 1,
   *    pageSize: 10,
   *    physiciansList: [
   *        {
   *        providerName: "First Last",
   *        practiceName: "Alexander Orthopaedic Associates",
   *        avatarURL: "https://..",
   *        totalSales: 17.0000,
   *        profitMargin: 4.0000,
   *        recommendationsSent: 17,
   *        productsPurchased: 12,
   *        },
   *        ...
   *    ]
   * }
   */
  getAnalytics: {
    /**
     * @example
     * Timeframe specific data
     * lastYearsRevenue: 114.9
     * todaysRevenue: 23.99
     */
    today: useFetch({ method: 'GET', endpoint: '/ReportsV2/dashboardanalyticstoday', unauthorized, token }),
    /**
     * @example
     * TimeFrame specific data
     * {
     *  thisYearRevenueList: [{ dayOfWeek: 'Sunday', revenue: 17.000 }, ...]
     *  lastYearRevenueList: [{ dayOfWeek: 'Sunday', revenue: 17.000 }, ...]
     * }
     */
    week: useFetch({ method: 'GET', endpoint: '/ReportsV2/dashboardanalyticsthisweek', unauthorized, token }),
    /**
     * @example
     * TimeFrame specific data
     * {
     *  thisYearRevenueList: [{ dayOfMonth: 'MM/DD/YYYY', revenue: 17.000 }, ...]
     *  lastYearRevenueList: [{ dayOfMonth: 'MM/DD/YYYY', revenue: 17.000 }, ...]
     * }
     */
    month: useFetch({ method: 'GET', endpoint: '/ReportsV2/dashboardanalyticsthismonth', unauthorized, token }),
    /**
     * @example
     * TimeFrame specific data
     * {
     *  monthlyRevenues: [{ month: 1, year: 2024, revenue: 17.000 }, ...]
     *  previousYearMonthlyRevenues: [{  month: 1, year: 2023, revenue: 17.000 }, ...]
     * }
     */
    year: useFetch({ method: 'GET', endpoint: '/ReportsV2/dashboardanalyticsthisyear', unauthorized, token }),
  },
})
