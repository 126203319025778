import { RevenueTable, Stats } from './pieces'
import { Loader } from 'components/Global'
import './Revenue.scss'
import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { customQueryKeys } from 'utilities/constants'
import { useAnalyticsV2 } from './useAnalyticsV2'
import { TotalSales } from '../TotalSales'

export const Revenue = () => {
  const qc = useQueryClient()
  const { isLoading, data, error } = useAnalyticsV2()

  useEffect(() => () => qc.cancelQueries([customQueryKeys.ANALYTICS, customQueryKeys.ANALYTICS_REV]), [qc])

  if (isLoading)
    return (
      <div style={{ minHeight: '575px' }} className="analytics-body__loading">
        <Loader />
      </div>
    )
  if (error) return <div className="analytics-body__error">There was an error.</div>

  return (
    <div className="analytics-body analytics-body__revenue">
      <div className="analytics-body__top">
        <TotalSales {...data} />
        <Stats data={data} />
      </div>
      <RevenueTable data={data.physicianList} />
    </div>
  )
}
