import StripeWrapper from '../Stripe/StripeWrapper'
import { PaymentElement } from '@stripe/react-stripe-js'
import { useStripeIntegration } from '../Stripe'
import { FormButtons } from '../FormButtons'
import { ScrollToTop } from 'components'
import { useClearTotals } from './useClearTotals'
import { Totals } from '../Components'
import { useBubbleProps } from 'utilities/hooks'
import { Spinner } from 'pages/Patient/Spinner'

export const CreditCardInfo = () => {
  useBubbleProps({
    custom: {
      title: '',
      message:
        'Please enter your credit card details. This transaction will appear as STANTON on your credit card statement.',
    },
  })

  const { mustResubmitForTaxAndShipping } = useClearTotals()

  return mustResubmitForTaxAndShipping ? null : (
    <StripeWrapper>
      <CreditCardForm />
    </StripeWrapper>
  )
}

const CreditCardForm = () => {
  const { handleStripeSubmit, isLoading } = useStripeIntegration()

  return (
    <>
      <ScrollToTop />
      {isLoading && <Spinner />}
      <div>
        <div className="payment-form__section-header">
          <h3>Payment INFORMATION</h3>
        </div>
        <form onSubmit={handleStripeSubmit}>
          <div className="payment-form__stripe-element">
            <PaymentElement options={{ wallets: { applePay: 'never', googlePay: 'never' } }} />
          </div>
          <FormButtons showStripePay disabled={isLoading} text={isLoading ? 'Loading...' : 'Continue'} />
        </form>
      </div>
      <Totals />
    </>
  )
}
