import { ProductPicker } from 'components'
import { useContext } from 'react'
import { MedBotsContext } from '../context'
import { useNavigate } from 'react-router-dom'
import { PageHeader } from './PageHeader'

export const ProductSelection = () => {
  const { actions, practiceId, lineItems, medbotId } = useContext(MedBotsContext)
  const nav = useNavigate()

  return (
    <>
      <PageHeader title={'Select A Product'} onBack={() => nav(-1)} />
      <ProductPicker
        products={lineItems}
        practiceId={practiceId}
        onAdd={product => actions.addProduct(product)}
        onFinish={() => nav(`/admin/medbots/${medbotId || 'review'}`)}
      />
    </>
  )
}
