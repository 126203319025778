import { useCallback, useContext, useEffect } from 'react'
import { StripePaymentContext } from '../context'
import { api as API } from 'utilities/constants'

export const useCreatePaymentIntent = () => {
  const { data, actions, clientSecret, totals, shippingAddress } = useContext(StripePaymentContext)

  const createPaymentIntent = useCallback(async () => {
    await fetch(`${API.API_URL}/patientorders/orderpaymentintent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        OrderId: data.id,
        OrderAmount: totals.totalPrice,
        Email: shippingAddress?.email,
        FirstName: shippingAddress?.firstName,
        LastName: shippingAddress?.lastName,
      }),
    })
      .then(r => r.json())
      .then(res => {
        if (res?.paymentIntent?.clientSecret) {
          actions.setPaymentIntent(res.paymentIntent)
        }
      })
  }, [data, actions, totals, shippingAddress])

  useEffect(() => {
    if (!clientSecret) createPaymentIntent()
  }, [clientSecret, createPaymentIntent])

  return { createPaymentIntent }
}
