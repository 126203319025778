import { Chart, TimeScale, LineElement, PointElement, CategoryScale, Tooltip } from 'chart.js'
import { Line } from 'react-chartjs-2'
import { months } from 'utilities/constants'
import { lastYearPointStyle, thisYearPointStyle } from './utils'
import { numberWithCommas } from 'utilities/utils'
import { useMemo } from 'react'
import { useAnalyticsContext } from '../../context'
import dayjs from 'dayjs'

Chart.register(LineElement, PointElement, TimeScale, CategoryScale, Tooltip)

///////////////////////////////

const colors = {
  lastYear: '#56c8f3',
  thisYear: '#168bb7',
}

const options = {
  maintainAspectRatio: false,
  responsive: true,
  animation: { duration: 1500 },
  elements: {
    line: { tension: 0.4 },
    point: { hitRadius: 15 },
  },
  scales: {
    x: {
      //   type: 'time', // need date adapter for chart.js --> chartjs-adapter-moment
      offset: true,
      grid: { display: false },
    },
    y: {
      min: 0,
      grid: { drawBorder: false, color: '#56c8f3' },
      ticks: {
        beginAtZero: true,
        maxTicksLimit: 6,
        callback: value => `$${numberWithCommas(value)}`, // adding a dollar sign to the values
      },
    },
  },
  plugins: {
    tooltip: {
      yAlign: 'bottom',
      xAlign: 'left',
      bodyFont: { size: 17 },
      displayColors: false,
      backgroundColor: ({ tooltipItems }) => tooltipItems?.[0]?.dataset?.borderColor ?? '#36AAD5', // here we set the default color so it doesn't go back to black
      callbacks: {
        label: coords => `$${numberWithCommas(coords.parsed.y.toFixed(0))}`,
        title: () => {}, //hiding the title
      },
    },
  },
}

export const Sales = ({ thisYear, lastYear }) => {
  const { scope } = useAnalyticsContext()

  const chartData = useMemo(
    () => ({
      datasets: [
        {
          data: formatData(thisYear, scope),
          borderColor: colors.thisYear,
          pointStyle: thisYearPointStyle,
        },
        {
          data: formatData(lastYear, scope),
          borderColor: colors.lastYear,
          pointStyle: lastYearPointStyle,
        },
      ],
    }),
    [scope, thisYear, lastYear]
  )

  return (
    <div className="total-sales-canvas-wrap">
      <Line options={options} data={chartData} />
    </div>
  )
}

function formatData(data, scope) {
  switch (scope) {
    case 'year':
      return data?.map(item => ({ x: months[item.month - 1].slice(0, 3), y: item?.revenue }))
    case 'month':
      return data.map(month => ({ x: dayjs(month.dayOfMonth).format('MMM DD'), y: month.revenue }))
    case 'week':
      return data.map(day => ({ x: day.dayofWeek, y: day.revenue }))
    case 'today':
      return [{ x: dayjs().format('MMM DD'), y: data }]
    default:
      return data?.map(item => ({ x: months[item.month - 1].slice(0, 3), y: item?.revenue }))
  }
}
