import { useCallback, useContext, useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import Lottie from 'react-lottie-player'
import { medbotLoadingData } from 'assets/lottie'
import { Button, Input } from 'components/Global'
import { ModalContainer } from 'components'
import { MainContext } from 'context'
import { MedBotDarkBlue } from 'assets/vector'
import { ForgotPassword } from 'components/Modals'
import './AdminLogin.scss'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

export const AdminLogin = () => {
  const {
    modal,
    admin: { actions, api },
  } = useContext(MainContext)
  const [animate, setAnimate] = useState(false)
  const nav = useNavigate()

  const onSuccess = data => {
    const { token, statusSummary, ...user } = data

    actions.setOrderSummary({ fetchedDate: dayjs(), ...statusSummary })
    actions.setUser({ ...user, practiceId: user?.practiceId || null })
    actions.authenticate(data?.token)

    const role = data?.role
    const providerType = data?.providerType || ''

    if (
      role.includes('ceo') ||
      role.includes('admin') ||
      (role.includes('doctor') && providerType.toLowerCase() === 'doctor')
    ) {
      nav('/admin')
    } else if (role.includes('stafftier2')) {
      nav('/admin/create-order/medbot', { replace: true })
    } else {
      nav('/admin/orders/all', { replace: true })
    }
  }

  useEffect(() => {
    let timer = setTimeout(() => setAnimate(true), 3000)
    return () => clearInterval(timer)
  }, [])

  const { mutate: login, isLoading: loading, error } = api.login({ queryOptions: { onSuccess } })

  return (
    <main className="login admin">
      <ModalContainer />
      <Lottie
        className={`loading-medbot ${animate && 'shrink'}`}
        role="img"
        play
        loop={true}
        animationData={medbotLoadingData}
      />
      <Form
        onSubmit={values => login({ body: values })}
        initialValues={{}}
        render={useCallback(
          ({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className={`login-form admin ${animate && 'show'}`}>
              <MedBotDarkBlue className="medtrix-logo-horizontal-admin" />
              <Input label="Email" type="text" />
              <Input label="Password" type="password" />
              <button
                type="button"
                className="forgot-password"
                onClick={() => modal?.actions?.open(<ForgotPassword />)}
                style={{ textDecoration: 'underline' }}
              >
                Forgot Password?
              </button>
              <Button type="submit">{loading ? 'loading...' : 'Login'}</Button>
              {!!error && <p className="error-msg">{error?.message}</p>}
            </form>
          ),
          [error, loading, animate, modal?.actions]
        )}
      />
    </main>
  )
}
