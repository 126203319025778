import { MainContext } from 'context'
import { useQueryClient } from 'react-query'
import { useEffect, useState, useContext, useMemo } from 'react'
import './Config.scss'
import './Analytics.scss'
import { useAdminStatus } from 'utilities/hooks'
import { customQueryKeys } from 'utilities/constants'
import { ScopeToggle, Revenue, AnalyticsProvider, useAnalyticsContext } from './Components'
import { PracticeComboBox } from 'pages/Admin/Orders/OrdersTable/PracticeComboBox.js/index.js'
import { Pagination } from 'components'

export const Analytics = () => {
  const qc = useQueryClient()
  const { admin } = useContext(MainContext)
  const { isAdmin, isLoading: adminLoad } = useAdminStatus()
  const [practiceId, selectPractice] = useState(admin?.user?.practiceId || 0)

  useEffect(() => () => qc.cancelQueries([customQueryKeys.ANALYTICS]), [qc])

  return (
    <AnalyticsProvider practiceId={practiceId || 0}>
      <AnalyticsInner>
        <div className="analytics-config-row1">
          <h4>Analytics</h4>
          <ScopeToggle />
          <div className="analytics-config-row1__right">
            {isAdmin && !adminLoad && <PracticeComboBox selectPractice={selectPractice} />}
          </div>
        </div>
      </AnalyticsInner>
    </AnalyticsProvider>
  )
}

export const AnalyticsInner = ({ children }) => {
  const state = useAnalyticsContext()

  const totalPages = useMemo(() => {
    if (state.totalRows) {
      return Math.ceil(state.totalRows / state.pagesize)
    }
    return 1
  }, [state])

  return (
    <>
      <div className="analytics-wrapper">
        <div className="analytics-config">{children}</div>
        <Revenue />
      </div>
      <Pagination page={state.page} totalPages={totalPages} selectPage={state.actions.setPage} />
    </>
  )
}
