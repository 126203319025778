import { AlertModal } from 'components'
import { MainContext } from 'context'
import { useCallback, useContext } from 'react'
import { useCart } from 'pages/Patient/Cart/context'

export const useCartControls = () => {
  const { modal } = useContext(MainContext)
  const { actions } = useCart()

  const handleDelete = useCallback(
    product =>
      modal.actions.open(
        <AlertModal
          message={`Are you sure you want to remove ${product?.productName || 'this item'} from your cart?`}
          onConfirm={() => {
            actions.removeItem(product.productId)
            modal.actions.close()
          }}
          onDeny={modal.actions.close}
          confirmText={'Remove'}
          denyText={'Cancel'}
        />
      ),
    [actions, modal.actions]
  )

  const handleAdd = useCallback(product => actions.editItem({ ...product, quantity: product.quantity + 1 }), [actions])
  const handleMinus = useCallback(
    product => {
      if (product?.quantity <= 1) return
      actions.editItem({ ...product, quantity: product.quantity - 1 })
    },
    [actions]
  )

  const onChangeRefillFrequency = useCallback(
    (newFrequency, product) => actions.editItem({ ...product, refillFrequency: newFrequency }),
    [actions]
  )

  return {
    handleDelete,
    handleAdd,
    handleMinus,
    onChangeRefillFrequency,
  }
}
