import { useContext, useState, useRef, useCallback, useEffect } from 'react'
import { MainContext } from 'context'
import { ChevronRightIcon } from 'assets/vector'
import { useQueryClient } from 'react-query'
import { useOutsideClick } from 'utilities/hooks'
import './PracticeComboBox.scss'

export const PracticeComboBox = ({
  searchable = true,
  getName,
  selectPractice,
  className,
  placeholder = 'Type Practice Name',
  defaultValue = '',
  clearText = 'ALL',
}) => {
  const { admin } = useContext(MainContext)
  const ref = useRef()
  const [showList, setShowList] = useState(false)
  const [searchString, search] = useState(null)
  const [selectedPractice, setSelectedPractice] = useState(defaultValue)
  const queryClient = useQueryClient()
  useOutsideClick({
    ref,
    customAction: () => {
      setShowList(false)
      search(null)
    },
  })

  const { data, isLoading, error } = admin.api.practice.all({
    params: { Filter: 'All', perPage: 1000 },
    keys: ['practice_combo_box'],
  })

  useEffect(() => () => queryClient.cancelQueries(['practice_combo_box']), [queryClient])

  const handleSelect = useCallback(
    ({ id, name }) => {
      selectPractice(id, name)
      setSelectedPractice(name)
      getName?.(name)
      search(null)
      setShowList(false)
    },
    [getName, selectPractice]
  )

  const handleClear = useCallback(() => {
    setShowList(false)
    search(null)
    getName?.(null)
    selectPractice('')
    setSelectedPractice('')
  }, [getName, selectPractice])

  const handleInputChange = useCallback(
    e => {
      setShowList(true)
      queryClient.invalidateQueries(['practices', 1000])
      search(e.target.value)
      e.target.value === '' && selectPractice('')
    },
    [queryClient, selectPractice]
  )

  const matchWhileSearching = useCallback(
    name => {
      if (!searchString) return true
      //cleaning search text of special characters
      const textToMatch = searchString?.toLowerCase().replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
      /* comparing the searchString with results from endpoint */
      const regex = new RegExp(textToMatch, 'g')
      return name.toLowerCase().match(regex)
    },
    [searchString]
  )

  const mapNames = useCallback(
    practices => {
      if (isLoading || error) return <li>{isLoading ? 'Loading...' : 'Error'}</li>

      return practices
        ?.reduce((a, c) => (c.active && matchWhileSearching(c.name) ? [...a, { name: c.name, id: c.id }] : a), [])
        ?.map?.((n, i) => (
          <li
            onClick={() => {
              handleSelect(n)
            }}
            key={i}
          >
            {n.name}
          </li>
        ))
    },
    [isLoading, error, handleSelect, matchWhileSearching]
  )

  return (
    <div ref={ref} onClick={() => setShowList(!showList)} className={`search-practices-wrap ${className || ''}`}>
      <button type="button" className="search-practices-button">
        {searchable ? (
          <input
            onChange={handleInputChange}
            className="search-practices-input"
            value={typeof searchString !== 'string' ? selectedPractice : searchString}
            placeholder={placeholder}
          />
        ) : (
          <span className="search-practices-label">{searchString ? searchString : 'FILTER BY PRACTICE'}</span>
        )}
        <ChevronRightIcon className="dropdown-arrow" />
      </button>
      <div className={`practices-list ${showList ? 'show' : 'hide'}`}>
        <ul>
          <li onClick={handleClear}>{clearText}</li>
          {mapNames(data?.practices)}
        </ul>
      </div>
    </div>
  )
}
