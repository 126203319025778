import { AlertModal } from 'components'
import { MainContext } from 'context'
import { Spinner } from 'pages/Patient/Spinner'
import { useContext } from 'react'
import { useHandleCancelOrder } from '../ShipButton/useHandleCancelOrder'
import './CancelButton.scss'

export const CancelButton = ({ className }) => {
  const {
    patient: { order: data },
    modal,
  } = useContext(MainContext)

  const { cancelOrder, isLoading } = useHandleCancelOrder(data)

  const confirmCancel = () => {
    modal?.actions?.open(
      <AlertModal
        onConfirm={() => cancelOrder()}
        cancelOrder
        message="Are you sure you want to cancel this order?"
        buttonText="Yes"
        denyText={'No'}
      />
    )
  }

  if (['pending delivery', 'cancelled', 'completed', 'refund'].includes(data?.status)) return null

  return isLoading ? (
    <Spinner />
  ) : (
    <button type="button" onClick={confirmCancel} className={`cancel-btn ${className || ''}`}>
      cancel order
    </button>
  )
}
