import { lazy } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MedtrixAdmins } from 'pages/Admin/Medtrix/Admins/MedtrixAdmins'
import { useAdminStatus, ResendInvite, lazyPortalWrap, lazyPatientWrap, lazyAdminWrap } from 'utilities'
import {
  AddPractice,
  PracticeList,
  PracticeDetails,
  SetPassword,
  Products,
  Analytics,
  EditProduct,
  EditProductChildren,
  CurrentOrder,
  Create,
  PatientLoginV2,
  MedBotsPages,
  Patients,
  Approved,
  PaymentFormV2,
  ShippingDetails,
  CreditCardInfo,
  Reports,
  SelectReportType,
  ReportView,
  Cart,
  SendWellness,
} from 'pages'
import './App.scss'

//lazy loaded modules
const OptInConsent = lazy(() => import('pages/OptInConsent'))
const Store = lazy(() => import('pages/Patient/Store'))
const PatientPortal = lazy(() => import('utilities/Portals/PatientPortal'))
const AdminPortal = lazy(() => import('utilities/Portals/AdminPortal'))
const AdminOrdersModule = lazy(() => import('pages/Admin/Orders'))

function App() {
  const { isAdmin } = useAdminStatus()
  return (
    <div className="App">
      <Routes>
        {/* UNPROTECTED ROUTES */}
        <Route path="consent" element={lazyPortalWrap(<OptInConsent />)} />
        <Route path="consent/:practiceId" element={lazyPortalWrap(<OptInConsent practice />)} />
        {/* REMOVING BECAUSE SECURITY RISK
           <Route path="create-password" element={<SetPassword create />} /> 
        */}
        <Route path="reset-password" element={<SetPassword reset />} />
        <Route path="/resendinvite" element={<ResendInvite />}></Route>
        {/* <Route path='/' */}

        {/* PATIENT PORTAL */}
        <Route path="" element={lazyPatientWrap(<PatientPortal />)}>
          <Route path="*" element={<Navigate to="" />} />
          <Route path="" element={<PatientLoginV2 />} />
          <Route path="order/:orderId">
            <Route path="" element={<CurrentOrder />} />

            <Route path="payment" element={<PaymentFormV2 />}>
              <Route path="shipping" element={<ShippingDetails />} />
              <Route path="checkout" element={<CreditCardInfo />} />
            </Route>
            <Route path="approved" element={<Approved />} />
          </Route>
          {/* Convenience Store */}
          <Route path="store/*" element={lazyPatientWrap(<Store />)} />
          {/* Cart */}
          <Route path="cart" element={<Cart />} />
        </Route>
        {/* ADMIN PORTAL */}
        <Route path="admin" element={lazyPortalWrap(<AdminPortal />)}>
          <Route path="*" element={<Navigate to="" />} />
          {isAdmin && <Route path="users" element={<MedtrixAdmins />} />}
          {isAdmin && <Route path="practices" element={<PracticeList />} />}
          {isAdmin && <Route path="practices/new" element={<AddPractice />} />}
          {isAdmin && <Route path="practices/:id" element={<PracticeDetails />} />}
          <Route path="practice" element={<PracticeDetails />} />
          <Route path="" element={<Analytics />} />

          {/* Send Wellness Store */}
          <Route path="send-wellness-store/*" element={<SendWellness />} />

          {/* Create and Edit MedBots */}
          <Route path="medbots" element={<MedBotsPages.Base />}>
            <Route path="" element={<MedBotsPages.AllMedBots />} />
            <Route path=":medbotId" element={<MedBotsPages.View edit />} />
            <Route path="review" element={<MedBotsPages.View create />} />
            <Route path="addproducts" element={<MedBotsPages.SelectProducts />} />
            <Route path="product/:productId" element={<MedBotsPages.ProductDetails />} />
            <Route path="create" element={<MedBotsPages.CreateName />} />
          </Route>
          {/* 
          Removed ~ user story 204
          <Route path="" element={<Dashboard admin />} /> 
          \*/}
          <Route path="products" element={<Products />} />
          <Route path="products/:productId/:practiceId" element={<EditProduct />}>
            <Route path="" element={<EditProductChildren />} />
          </Route>

          <Route path="orders/:filter/*" element={lazyAdminWrap(<AdminOrdersModule />)} />

          <Route path="create-order/:type" element={<Create />} />
          <Route path="patients" element={<Patients />} />
          {/* REPORTS */}
          <Route path="reports" element={<Reports />}>
            <Route path="" element={<SelectReportType />} />
            <Route path=":reportType" element={<ReportView />} />
          </Route>
        </Route>
      </Routes>
    </div>
  )
}

export default App
