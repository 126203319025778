import { useLocation, useParams } from 'react-router-dom'
import { useContext } from 'react'
import { MainContext } from 'context'
import { useCart } from 'pages/Patient/Cart/context'
// const noLoginRequiredStatus = ['draft order', 'aging retail']

export const useOrder = ({ current = true }) => {
  const { patient } = useContext(MainContext)
  const { orderId } = useParams()
  const { state } = useLocation()
  const { actions } = useCart()

  const { isLoading, error } = patient.api.getOrder({
    variables: { orderId },
    queryOptions: {
      enabled: current,
      refetchOnMount: true,
      onSuccess: res => {
        //init cart if reloading order that was from cart
        if (state?.from === 'cart') {
          actions.setLineItems(res.LineItems)
          actions.toggleCartIcon(true)
        }
        /*
        if (!noLoginRequiredStatus.includes(res.status) && !patient.user) {
          patient?.actions.toggleRequireLogin(true)
        }
          if (FlyerId && noLoginRequiredStatus.includes(res.status)) {
          nav('flyer')
        }
        */

        patient?.actions.toggleHasAgreed(res.hasAgreedToTerms)
        current && patient.actions.setOrder(res)
      },
    },
    keys: ['patient_order'],
  })

  return {
    isLoading: isLoading,
    error: error,
    data: patient.order,
    orderId,
  }
}
