import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useContext, useMemo } from 'react'
import { MainContext } from 'context'
import { useOrder } from 'pages/Patient/CurrentOrder/useOrder'
import { StripePaymentProvider } from './Stripe'
import './PaymentForm.scss'
import { StripeFooter } from './Components'
import { Spinner } from 'pages/Patient/Spinner'

export const PaymentFormV2 = () => {
  const { patient } = useContext(MainContext)
  const { isLoading, orderId } = useOrder({ current: true })
  const { pathname } = useLocation()

  const isValidPathname = useMemo(() => pathname.includes('shipping') || pathname.includes('checkout'), [pathname])
  const blockScreenForLoading = useMemo(() => !!patient?.order && isLoading, [isLoading, patient?.order])

  if (!isValidPathname) return <Navigate replace to={`/order/${patient.order.id}/payment/shipping`} />
  if (!isLoading && patient.order.status !== 'draft order') return <Navigate replace to={`/order/${orderId}`} />

  return (
    <StripePaymentProvider isLoading={isLoading} data={patient.order}>
      {blockScreenForLoading ? (
        <Spinner />
      ) : (
        <div className="payment-form-wrapper">
          <Outlet />
          <StripeFooter />
        </div>
      )}
    </StripePaymentProvider>
  )
}
