import { useMemo } from 'react'

export const initStripePayment = {
  shippingAddress: {},
  clientSecret: null,
  paymentIntent: null,
  isLoading: false,
  //we can only set the customer once on payment intent
  //saving it here to compare
  stripeInstance: null,
  /* order data that cannot be overwritten by page refresh */
  totals: null, //this should be tax and shipping, if null, redirect back to shipping form
}

const actions = {
  SET_CLIENT_SECRET: 'SET_CLIENT_SECRET',
  SET_SHIPPING_ADDRESS: 'SET_SHIPPING_ADDRESS',
  SET_PAYMENT_INTENT: 'SET_PAYMENT_INTENT',
  DELETE_PAYMENT_INTENT: 'DELETE_PAYMENT_INTENT',
  SET_STRIPE_INSTANCE: 'SET_STRIPE_INSTANCE',
  SET_TOTALS: 'SET_TOTALS',
  SET_IS_LOADING: 'SET_IS_LOADING',
}

const StripePaymentReducer = (draft, { type, payload }) => {
  switch (type) {
    case actions.SET_CLIENT_SECRET:
      return void (draft.clientSecret = payload)
    case actions.SET_SHIPPING_ADDRESS:
      return void (draft.shippingAddress = payload)
    case actions.SET_PAYMENT_INTENT:
      if (payload?.clientSecret) {
        draft.clientSecret = payload.clientSecret
      }
      return void (draft.paymentIntent = payload)
    case actions.DELETE_PAYMENT_INTENT:
      draft.stripeInstance = null
      draft.clientSecret = null
      return void (draft.paymentIntent = null)
    case actions.SET_STRIPE_INSTANCE:
      return void (draft.stripeInstance = payload)
    case actions.SET_TOTALS:
      return void (draft.totals = payload)
    case actions.SET_IS_LOADING:
      return void (draft.isLoading = payload)
    default:
      return draft
  }
}

export const useStripePaymentActions = dispatch =>
  useMemo(
    () => ({
      setClientSecret: clientSecret => dispatch({ type: actions.SET_CLIENT_SECRET, payload: clientSecret }),
      setShippingAddress: address => dispatch({ type: actions.SET_SHIPPING_ADDRESS, payload: address }),
      setPaymentIntent: paymentIntent => dispatch({ type: actions.SET_PAYMENT_INTENT, payload: paymentIntent }),
      deletePaymentIntent: () => dispatch({ type: actions.DELETE_PAYMENT_INTENT }),
      setStripeInstance: instance => dispatch({ type: actions.SET_STRIPE_INSTANCE, payload: instance }),
      setTotals: totals => dispatch({ type: actions.SET_TOTALS, payload: totals }),
      setIsLoading: isLoading => dispatch({ type: actions.SET_IS_LOADING, payload: isLoading }),
    }),

    [dispatch]
  )

export default StripePaymentReducer
