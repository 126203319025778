import { useCallback } from 'react'
import { Outlet, Navigate, useLocation } from 'react-router-dom'

const routeChecks = [pathname => pathname.includes('/admin/create-order')]

export const StaffTier2Routes = () => {
  const { pathname } = useLocation()

  const runRouteChecks = useCallback(() => {
    let i = 0
    while (i < routeChecks.length) {
      if (!routeChecks[i](pathname)) {
        return false
      }
      i++
    }
    return true
  }, [pathname])

  return runRouteChecks() ? <Outlet /> : <Navigate replace to="/admin/create-order/medbot" />
}
