import { MainContext } from 'context'
import { useCallback, useContext } from 'react'
import { Form } from 'react-final-form'
import { InnerShippingForm } from './InnerShippingForm'
import { useTaxAndShipping } from './useTaxAndShipping'
import { useSyncOrderWithBackend } from '../useSyncOrderWithBackend'
import { FormButtons } from '../FormButtons'
import { StripePaymentContext, useDeletePaymentIntent } from '../Stripe'
import { ScrollToTop } from 'components'
import { Subtotal } from '../Components'
import { useBubbleProps } from 'utilities/hooks'

export const ShippingDetails = () => {
  useBubbleProps({ custom: { message: 'Please enter your shipping details.' } })
  const { patient } = useContext(MainContext)
  const { isSyncing } = useSyncOrderWithBackend()
  const { getTotals, isLoading } = useTaxAndShipping()
  const { shippingAddress } = useContext(StripePaymentContext)
  useDeletePaymentIntent()

  const onSubmit = useCallback(formValues => getTotals(patient.order.id, formValues), [patient.order, getTotals])

  return (
    <>
      <ScrollToTop />
      <Form
        initialValues={shippingAddress}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <InnerShippingForm handleSubmit={handleSubmit}>
            <FormButtons disabled={isSyncing || isLoading} text={isSyncing || isLoading ? 'Loading...' : 'Continue'} />
          </InnerShippingForm>
        )}
      />
      <Subtotal />
    </>
  )
}
