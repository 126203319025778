import { MainContext } from 'context'
import { useCallback, useContext } from 'react'
import { MedBotsContext } from '../../context'
import { AlertModal, SuccessModal } from 'components'

export const useMedbotUpdate = () => {
  const { admin, modal } = useContext(MainContext)
  const { actions, medbotId, hasEdits, ...medbot } = useContext(MedBotsContext)

  const { mutate, ...rest } = admin.api.createEditMedbots.updateMedbot({
    variables: { medbotId },
    queryOptions: {
      onSuccess: () => modal.actions.open(<SuccessModal title="MedBot updated!" />),
      onError: () => modal.actions.open(<AlertModal message={'Could not update MedBot'} />),
    },
  })

  const updateMedBot = useCallback(
    () =>
      mutate({
        body: {
          medbotDraftOrder: { medbotId, ...medbot },
        },
      }),
    [medbot, medbotId, mutate]
  )

  return { updateMedBot, ...rest }
}
