import { useContext, useCallback } from 'react'
import { CreateOrderContext } from '../../context'
import './SelectProduct.scss'
import { ProductPicker } from 'components'
import { pages } from '../../constants'

export const SelectProduct = () => {
  const { products, actions, provider } = useContext(CreateOrderContext)

  const onAdd = product => actions.setProducts([...products, product])

  const goToReview = useCallback(() => actions.setPage(pages.REVIEW), [actions])

  return (
    <ProductPicker
      products={products} //products already in cart
      practiceId={provider?.practiceId}
      /*
      won't need these for adding a product
      onUpdate={onUpdate}
      onDelete={() => console.log('DELETE THIS')}
      */
      onAdd={onAdd}
      onFinish={goToReview}
    />
  )
}
