import { useContext } from 'react'
import { MainContext } from 'context'
import { Login } from 'pages'

const PrivateRoute = ({ children, admin, practice }) => {
  const {
    admin: { authentication: token },
    patient: { user: patientData, requireLogin },
  } = useContext(MainContext)

  if (admin) return token ? children : <Login />
  if (practice) return token ? children : <Login />
  return patientData || !requireLogin ? children : <Login />
}

export default PrivateRoute
