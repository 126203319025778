import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { pages, types } from '../constants'

//listening to changes in url single order flow vs medbot flow
export const useSetType = actions => {
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname.includes('medbot')) {
      actions.setType(types.MEDBOT)
    }

    if (pathname.includes('single')) {
      actions.setType(types.SINGLE)
    }
  }, [pathname, actions])
}

export const backPage = draft => {
  const { page, type, products, medbot } = draft

  switch (page) {
    case pages.PRODUCT_VIEW:
      return pages.REVIEW
    case pages.SELECT_MEDBOT:
      return pages.SELECT_MEDBOT
    case pages.SELECT_PROVIDER:
      return type === types.SINGLE ? pages.REVIEW : medbot ? pages.REVIEW : pages.SELECT_MEDBOT
    case pages.SELECT_PRODUCT:
      return type === types.SINGLE && !products?.length ? pages.SELECT_PROVIDER : pages.REVIEW
    case pages.REVIEW:
      return type === types.MEDBOT ? pages.SELECT_PROVIDER : pages.SELECT_PRODUCT
    case pages.SELECT_PATIENT:
      return pages.REVIEW
    default:
      return page
  }
}
