import './ScopeToggle.scss'
import { useAnalyticsContext } from '../context'

const scopes = ['today', 'week', 'month', 'year']

export const ScopeToggle = () => {
  const { scope: active, actions } = useAnalyticsContext()

  return (
    <div className="scope-toggle">
      {scopes.map((scope, i) => (
        <button
          onClick={() => actions.setScope(scope)}
          className={`scope-toggle__button ${scope === active ? 'active' : ''}`}
          key={i}
        >
          <span>{scope === 'today' ? scope : `This ${scope}`}</span>
        </button>
      ))}
    </div>
  )
}
