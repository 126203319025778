import { useContext, useMemo, useState } from 'react'
import { MainContext } from 'context'
import { useParams } from 'react-router-dom'
import { useStore } from 'pages/Patient/Store/context'

export const usePracticeLogo = () => {
  const { practiceId } = useParams()
  const { patient } = useContext(MainContext)
  const [logo, setLogo] = useState('')
  const { practice } = useStore()

  const pId = useMemo(() => practiceId || patient?.order?.practiceId || practice?.id, [practice, patient, practiceId])

  patient?.api?.getPracticeLogo({
    variables: { practiceId: pId },
    queryOptions: {
      enabled: !!pId,
      onSuccess: data => {
        if (data instanceof Blob) {
          let string = URL.createObjectURL(data)
          setLogo(string)
          patient.actions.setPracticeLogo(string)
        }
      },
    },
  })

  return { logo }
}
