import { useEffect, useContext } from 'react'
import { MainContext } from 'context'
import { AlertModal } from 'components'
import { StripePaymentContext } from '../Stripe'
import { useNavigate } from 'react-router-dom'

export const useClearTotals = () => {
  const { modal } = useContext(MainContext)
  const { totals, actions } = useContext(StripePaymentContext)
  const nav = useNavigate()

  useEffect(() => {
    if (!totals) {
      modal.actions.open(
        <AlertModal message="Your session has expired. Please resubmit your shipping information to continue." />
      )
      nav(-1)
    }

    //if the user leaves the page using browswer arrow, we reset the totals
    return () => actions.setTotals(null)
  }, [actions, nav, modal.actions, totals])

  return { mustResubmitForTaxAndShipping: !totals }
}
