import { MainContext } from 'context'
import { useContext, useMemo } from 'react'
import { useBubbleProps } from 'utilities/hooks'

export const checkInStock = data =>
  !!(data?.lineItems || data?.LineItems || data?.localLineItems)?.filter(prod => !prod?.isOutOfStock).length

const msgKeys = {
  open: 'Thank you, your order is being processed! This transaction will appear as STANTON on your credit card statement. Questions about your order? Call us at 727-353-3478.',
  'draft order': 'Please see the medical products I have recommended for you.',
  paid: 'Thank you, your order is being processed! This transaction will appear as STANTON on your credit card statement. Questions about your order? Call us at 727-353-3478.',
  shipped: 'Your order has been processed and shipped!',
  completed: 'Your order has been delivered. Be sure to view the product instructions.',
  delivered: 'Your order has been delivered. Be sure to view the product instructions.',
  'refund pending': 'Your request for a refund is pending.',
  'refund succeeded': 'Your product(s) have been refunded',
  refunded: 'Your products have been refunded.',
  cancelled: 'Your order has been cancelled.',
  'less expensive': 'Plese view the less expensive alternatives',
}

const errMsg = `Ooops, we've run into an unexpected error. Please try again or contact us at support@medbot.com`

export const useHandleBubbleMessage = ({ error, isLoading = false }) => {
  const {
    patient: { order },
  } = useContext(MainContext)

  const message = useMemo(
    () => (error ? errMsg : isLoading ? '' : msgKeys[order?.status]),
    [order?.status, isLoading, error]
  )

  useBubbleProps({
    isOpen: !isLoading,
    custom: { message },
  })
}
