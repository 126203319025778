import { Button } from 'components'
import { useCallback } from 'react'
import './ActionButtons.scss'
import { useStoreCheckout } from '../../hooks/useStoreCheckout'
import { useCart } from 'pages/Patient/Cart/context'
import { useNavWithState } from 'utilities/Patient'

export const StoreActionButtons = ({ goBackTo = null }) => {
  const { navWithState } = useNavWithState()
  const { createOrder, isLoading } = useStoreCheckout()
  const { LineItems } = useCart()

  const handleBack = useCallback(() => navWithState(goBackTo ?? -1), [navWithState, goBackTo])

  const onCheckout = useCallback(() => createOrder({ lineItems: LineItems }), [createOrder, LineItems])

  return (
    <div className="store-action-buttons">
      {!!LineItems.length && (
        <Button disabled={isLoading} onClick={onCheckout}>
          {isLoading ? 'Loading...' : 'Check Out'}
        </Button>
      )}
      <Button onClick={handleBack}>Back</Button>
    </div>
  )
}
