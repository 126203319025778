import merge from 'lodash/merge'
import { useMemo } from 'react'
import { useFetch } from 'utilities/api'
import { emptyCart } from 'utilities/constants'
import { assignDoctorToCart, calcCartSubtotal, calcPrices, markAsChanged } from './utils'

const keys = {
  LOG_IN: 'LOG_IN',
  STORE_USER: 'STORE_USER',
  SET_BUBBLE_OPEN: 'SET_BUBBLE_OPEN',
  SET_BUBBLE_PROPS: 'SET_BUBBLE_PROPS',
  SET_ORDER: 'SET_ORDER',
  REMOVE_ITEM: 'REMOVE_ITEM',
  EDIT_ITEM: 'EDIT_ITEM',
  SET_CART: 'SET_CART',
  ADD_TO_CART: 'ADD_TO_CART',
  REMOVE_FROM_CART: 'REMOVE_FROM_CART',
  EDIT_CART_ITEM: 'EDIT_CART_ITEM',
  VIEW_PRODUCT: 'VIEW_PRODUCT',
  NOTIFY: 'NOTIFY',
  SET_SHIPPING_ADDRESS: 'SET_SHIPPING_ADDRESS',
  REPLACE_PRODUCT: 'REPLACE_PRODUCT',
  SET_VIEW_ORDER: 'SET_VIEW_ORDER',
  SET_PRACTICE_LOGO: 'SET_PRACTICE_LOGO',
  SHOW_NAV: 'SHOW_NAV',
  SET_REQUIRE_LOGIN: 'SET_REQURE_LOGIN',
  TOGGLE_HAS_AGREED: 'TOGGLE_HAS_AGREED',
}

export const patientInit = {
  authentication: null,
  hasAgreed: true,
  requireLogin: false,
  showNav: false,
  practiceLogo: null,
  user: null,
  order: {},
  viewOrder: {},
  shippingAddress: {},
  viewProductContext: {},
  viewProduct: { product: null, order: null },
  /* The doctor with the most items in cart gets the order assigned */
  shoppingCart: emptyCart,
  notification: { text: null, show: false },
  bubble: {
    showBubble: true,
    bubbleProps: {
      image: '',
      title: '',
      message: 'Please see the medical products I have recommended for you.',
    },
  },
}

export const PatientReducer = (draft, { payload, type }) => {
  let i
  switch (type) {
    case keys.SHOW_NAV:
      return void (draft.showNav = payload)
    case keys.LOG_IN:
      return void (draft.user = { ...draft.user, ...payload.user })
    case keys.STORE_USER:
      return void localStorage.setItem('medtrix_patient_login', JSON.stringify(draft))
    case keys.SET_BUBBLE_OPEN:
      return void (draft.bubble.showBubble = payload)
    case keys.SET_BUBBLE_PROPS:
      return void (draft.bubble.bubbleProps = payload.replace
        ? payload.props
        : merge(draft.bubble.bubbleProps, payload.props))
    case keys.NOTIFY:
      return void (draft.notification = payload)
    case keys.SET_ORDER:
      return void (draft.order = payload)
    case keys.SET_VIEW_ORDER:
      return void (draft.viewOrder = payload)
    case keys.SET_PRACTICE_LOGO:
      return void (draft.practiceLogo = payload)
    case keys.SET_REQUIRE_LOGIN:
      return void (draft.requireLogin = payload)
    case keys.TOGGLE_HAS_AGREED:
      return void (draft.hasAgreed = payload)
    /* 
    editing current order below
    */
    case keys.REMOVE_ITEM:
      draft.order.LineItems = draft?.order?.LineItems?.filter(item => item.productId !== payload.productId)
      markAsChanged(draft)
      return void calcPrices(draft)
    case keys.EDIT_ITEM:
      const lineItems = draft?.order?.LineItems || draft?.order?.lineItems || draft?.order?.localLineItems
      i = lineItems?.findIndex(item => item?.productId === payload.productId)
      draft.order.LineItems[i] = payload
      markAsChanged(draft)
      return void calcPrices(draft)
    /*
      editing cart items below
      */
    case keys.SET_CART:
      return void (draft.shoppingCart = payload)
    case keys.EDIT_CART_ITEM:
      i = draft?.shoppingCart?.LineItems?.findIndex(item => item?.id === payload?.id)
      draft.shoppingCart.LineItems[i] = payload
      return void calcCartSubtotal(draft)
    case keys.ADD_TO_CART:
      draft.shoppingCart.practiceId = payload?.practiceId
      draft.shoppingCart.doctor.t3ID = payload?.doctorId
      i = draft.shoppingCart.LineItems.findIndex(item => item.productId === payload.productId)
      if (i === -1 || draft.shoppingCart.length === 0) {
        draft.shoppingCart.LineItems.push(payload)
      } else {
        draft.shoppingCart.LineItems[i] = payload
      }
      calcCartSubtotal(draft)
      return void assignDoctorToCart(draft)
    case keys.REMOVE_FROM_CART:
      draft.shoppingCart.LineItems = draft.shoppingCart.LineItems.filter(item => item.productId !== payload.productId)

      return void calcCartSubtotal(draft)
    // changeAddress
    case keys.SET_SHIPPING_ADDRESS:
      // markAsChanged(draft)
      // draft.shoppingCart.isChanged = true
      return void (draft.shippingAddress = merge(draft.shippingAddress, payload))
    case keys.VIEW_PRODUCT:
      return void (draft.viewProduct = payload)
    ///////////////
    case keys.REPLACE_PRODUCT:
      const { oldProduct, newProduct } = payload
      i = draft?.order?.LineItems?.findIndex(item => item?.productId === oldProduct.productId)
      draft.order.LineItems[i] = {
        ...newProduct,
        orderId: oldProduct.orderId,
        retailPrice: newProduct.variants[0].retailPrice,
        quantity: 1,
        markupAmount: newProduct.variants[0].markupAmount,
      }
      markAsChanged(draft)
      return void calcPrices(draft)
    ///////////////////////
    default:
      return draft
  }
}

export const usePatientActions = (dispatch, api) => {
  return {
    api: {
      store: {
        getStore: useFetch({ method: 'GET', endpoint: '/store/processinvite/{invitationId}' }),
        getConditions: useFetch({ method: 'GET', endpoint: '/store/conditions/{storeId}' }),
        getProducts: useFetch({ method: 'GET', endpoint: '/store/products/{conditionId}' }),
        createOrder: useFetch({ method: 'POST', endpoint: '/shopify/createdraftorder/{practiceId}' }),
      },
      getPracticeConsentInfo: useFetch({ method: 'GET', endpoint: '/store/practice/optinscreen/{practiceId}' }),
      practiceConsent: useFetch({ method: 'POST', endpoint: '/store/practice/saveconsent/' }),
      consent: useFetch({ method: 'POST', endpoint: '/patients/saveconsent/' }),
      agreeToTerms: useFetch({ method: 'POST', endpoint: '/patients/hasAgreedToTerms/{patientId}' }),
      payOrderV2: useFetch({ method: 'POST', endpoint: '/patientorders/v2/payment/{orderId}' }),
      createFromShoppingCart: useFetch({
        method: 'POST',
        endpoint: '/shopify/createdraftorder/{practiceId}',
      }),
      sendResetPassword: useFetch({ method: 'POST', endpoint: '/Account/resetpassword' }),
      loginV2: useFetch({ method: 'POST', endpoint: '/login' }),
      getTaxAndShipping: useFetch({ method: 'POST', endpoint: '/patientorders/total' }),
      updateOrder: useFetch({ method: 'PUT', endpoint: '/patientorders/v2/{orderId}' }),
      getOrder: useFetch({ method: 'GET', endpoint: '/patientorders/v2/{orderId}' }),
      cancelDraftOrder: useFetch({
        method: 'PUT',
        endpoint: '/patientorders/{orderId}/{practiceId}/draft/cancel/{shopifyDraftOrderId}',
      }),

      getLessExp: useFetch({ method: 'POST', endpoint: '/product/getlessexpensivebymedbotorder' }),
      refuseMedbot: useFetch({ method: 'GET', endpoint: '/medbot/patients/refused/{medbotId}/{patientId}' }),
      getPracticeLogo: useFetch({ method: 'GET', endpoint: '/avatars/practice/{practiceId}' }),
      getAlternates: useFetch({
        method: 'GET',
        endpoint: '/product/practiceProductAlternatives/{practiceId}/{productId}',
      }),
      finalizeOrder: useFetch({ method: 'POST', endpoint: '/patientorders/finalizeorder/{orderId}' }),
      getDocAvatar: useFetch({ method: 'GET', endpoint: '/orders/GetDoctorAvatarurl/{doctorId}' }),
      recordNoPurchaseReason: useFetch({ method: 'GET', endpoint: '/patientorders/nonpurchasedlog' }),
      getDoc: useFetch({ method: 'GET', endpoint: '/userdetails/doctorwithAvatar/{doctorId}' }),
      login: useFetch({ method: 'POST', endpoint: `/patientorders/login/{orderId}` }),
      payOrder: useFetch({ method: 'POST', endpoint: `/patientorders/postpayment/{orderId}` }),
      cancelOrder: useFetch({
        method: 'PUT',
        endpoint: '/patientorders/{orderId}/{practiceId}/cancel/{shopifyOrderId}',
      }),
      createPayIntent: useFetch({ method: 'POST', endpoint: '/patientorders/orderpaymentintent' }),
      verifyOrder: useFetch({ method: 'PUT', endpoint: '/patientorders/verifyorderinfo' }),
      toggleSMSPreferences: useFetch({ method: 'POST', endpoint: '/patients/smsnotifications/{patientId}' }),
      toggleEmailPreferences: useFetch({ method: 'POST', endpoint: '/patients/emailnotifications/{patientId}' }),
      getPastOrders: useFetch({ method: 'GET', endpoint: '/patientorders/past/{patientId}' }),
      getRefundedProducts: useFetch({ method: 'GET', endpoint: '/orders/products/refunded/{practiceId}' }),
      getRefundedProductDetails: useFetch({ method: 'GET', endpoint: '/orders/products/refunded/detail/{lineItemId}' }),
      refundProduct: useFetch({ method: 'POST', endpoint: '/patientorders/orderproductrefund/{orderId}' }),
      autoShip: {
        getProducts: useFetch({ method: 'GET', endpoint: '/orders/products/autoship/{patientId}' }),
        toggle: useFetch({ method: 'PUT', endpoint: '/orders/products/autoship/{lineItemId}' }),
        turnOffNext: useFetch({ method: 'PUT', endpoint: '/orders/products/autoship/nextautoshipoff' }),
      },
    },
    actions: useMemo(
      () => ({
        toggleHasAgreed: hasAgreed => dispatch({ type: keys.TOGGLE_HAS_AGREED, payload: hasAgreed }),
        showNav: bool => dispatch({ type: keys.SHOW_NAV, payload: bool }),
        // Login
        setShippingAddress: newAddress => dispatch({ type: keys.SET_SHIPPING_ADDRESS, payload: newAddress }),
        setBubbleOpen: bool => dispatch({ type: keys.SET_BUBBLE_OPEN, payload: bool }),
        setBubbleProps: (props, replace = false) =>
          dispatch({ type: keys.SET_BUBBLE_PROPS, payload: { props, replace } }),
        setUser: user => dispatch({ type: keys.LOG_IN, payload: { user } }),
        setOrder: order => dispatch({ type: keys.SET_ORDER, payload: order }),
        notify: (text, show) => dispatch({ type: keys.NOTIFY, payload: { text, show } }),
        ////******************* */
        ////******** CART *********** */
        setCart: newCart => dispatch({ type: keys.SET_CART, payload: newCart }),
        editCartItem: newItem => dispatch({ type: keys.EDIT_CART_ITEM, payload: newItem }),
        addToCart: product => {
          dispatch({ type: keys.ADD_TO_CART, payload: product })
          dispatch({ type: keys.NOTIFY, payload: { text: 'Product added to cart', show: true } })
        },
        toggleRequireLogin: payload => dispatch({ type: keys.SET_REQUIRE_LOGIN, payload }),
        removeFromCart: product => {
          dispatch({ type: keys.REMOVE_FROM_CART, payload: product })
          dispatch({ type: keys.NOTIFY, payload: { text: 'Product removed from cart', show: true } })
        },
        ////******************* */
        setPracticeLogo: payload => dispatch({ type: keys.SET_PRACTICE_LOGO, payload }),
        ////********CURRENT ORDER*********** */
        removeItem: item => dispatch({ type: keys.REMOVE_ITEM, payload: item }),
        editItem: newItem => dispatch({ type: keys.EDIT_ITEM, payload: newItem }),
        ////******************* */
        setViewOrder: order => dispatch({ type: keys.SET_VIEW_ORDER, payload: order }),
        ////********Select Product*********** */
        viewProduct: (product, order = null) => dispatch({ type: keys.VIEW_PRODUCT, payload: { product, order } }),
        ////////////////
        replaceProduct: (oldProduct, newProduct) =>
          dispatch({ type: keys.REPLACE_PRODUCT, payload: { oldProduct, newProduct } }),
      }),
      [dispatch]
    ),
  }
}
