import { useCallback, useContext } from 'react'
import './SelectProvider.scss'
import { ProviderList } from 'components/AlphabeticList'
import { CreateOrderContext } from '../../context'
import { pages, types } from '../../constants'

export const SelectProvider = () => {
  const { actions, type, products } = useContext(CreateOrderContext)

  const onSelectProvider = useCallback(
    provider => {
      if (type === types.MEDBOT) actions.setPage(pages.REVIEW)
      if (type === types.SINGLE) {
        actions.setPage(!products.length ? pages.SELECT_PRODUCT : pages.REVIEW)
      }
      actions.setProvider(provider)
    },
    [actions, type, products?.length]
  )

  return <ProviderList onSelectProvider={onSelectProvider} />
}
