import { useCallback, useContext, useMemo } from 'react'
import { CreateOrderContext, CreateOrderProvider } from './context/CreateOrderContext'
import { pages, titles, types } from './constants'
import { ReviewOrder, SelectMedbot, SelectPatient, SelectProduct, SelectProvider } from './components'
import './CreateOrder.scss'
import { useSvgButton } from 'utilities/hooks'
import { BackButtonIcon } from 'assets/vector'
import { ProductReview } from './components/Product'

export const CreateOrder = () => {
  const { actions, ...state } = useContext(CreateOrderContext)

  const renderPage = useCallback(() => {
    switch (state.page) {
      case pages.REVIEW:
        return <ReviewOrder />
      case pages.SELECT_MEDBOT:
        return <SelectMedbot />
      case pages.SELECT_PATIENT:
        return <SelectPatient />
      case pages.SELECT_PROVIDER:
        return <SelectProvider />
      case pages.SELECT_PRODUCT:
        return <SelectProduct />
      case pages.PRODUCT_VIEW:
        return <ProductReview />
      default:
        return null
    }
  }, [state.page])

  const showBackButton = useMemo(() => {
    const { type, page, provider } = state
    if (type === types.SINGLE && page === pages.SELECT_PROVIDER && !provider) {
      return false
    }

    if (type === types.MEDBOT && page === pages.SELECT_MEDBOT) {
      return false
    }

    return true
  }, [state])

  return (
    <div className="create-order-wrapper">
      <div className="create-order-header">
        <h4 className="create-order-header__title">{titles[state?.page]}</h4>
        {useSvgButton(
          <BackButtonIcon
            onClick={actions?.back}
            className={`create-order-header__back-button ${showBackButton ? 'visible' : 'hide'}`}
          />
        )}
      </div>
      {renderPage()}
    </div>
  )
}

export const Create = () => (
  <CreateOrderProvider>
    <CreateOrder />
  </CreateOrderProvider>
)
