import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useMemo, useContext } from 'react'
import { StripePaymentContext } from './context'
import { useCreatePaymentIntent } from './StripeActions'
import { Spinner } from 'pages/Patient/Spinner'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)

const StripeWrapper = ({ children }) => {
  const { clientSecret } = useContext(StripePaymentContext)
  useCreatePaymentIntent()

  const options = useMemo(
    () => ({
      clientSecret,
      appearance: {
        theme: 'stripe',
        variables: {
          colorPrimary: '#56c8f',
          colorText: '#168bb7',
          spacingUnit: '6px',
          fontFamily: 'Roboto, sans-serif',
          colorDanger: '#ff6c00',
        },
        rules: {
          '.Label': {
            fontSize: '15px',
            marginBottom: '7px',
          },
          '.Input': {
            fontFamily: 'Roboto, sans-serif',
            borderRadius: '50px',
            border: 'solid 1px #79c6ef',
            padding: '16px',
            fontWeight: 500,
            color: '#838383',
            backgroundColor: '',
          },
          '.Input--invalid': {
            border: '#ff6c00 solid 1px',
          },
          '.Input--invalid:focus': {
            border: '#ff6c00 solid 1px',
          },
          '.Input:focus': {
            border: 'solid 2px #168bb7',
            boxShadow: 'none',
          },
        },
      },
    }),
    [clientSecret]
  )

  return clientSecret ? (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  ) : (
    <Spinner />
  )
}

export default StripeWrapper
