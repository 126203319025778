import { PracticeLogo, Button, Input, CloseButton, Dropdown, Loader } from 'components/Global'
import { Phone, Zip } from 'components/Global/Input/MaskedInputs'
import { States } from 'utilities/constants'
import { ConditionalForm } from '../ConditionalForm'
import { useUpdate } from 'utilities/hooks'
import { generalShape as dataShape } from './dataShapes'

export const General = ({ closeModal, add, id, data, postAvatar, avatar, ...rest }) => {
  const { reqUpdate, isLoading } = useUpdate({
    dataShape,
    errorMessage: 'Could not update practice info',
  })

  if (isLoading) return <Loader />

  return (
    <div {...rest} className={`modal-content create-practice-modal create-practice-modal-wrapper ${add ? 'add' : ''}`}>
      {add ? null : <CloseButton onClick={() => closeModal()} />}
      <h2>{add ? 'General Practice Information' : 'Edit Practice Information'}</h2>
      <ConditionalForm
        onSubmit={(values, { getState }) => {
          const {
            modified: { avatar: newAvatar, ...others },
          } = getState()
          const { avatar, ...vals } = values
          if (!add) {
            // if any fields NOT avatar were modified update
            Object.values(others).includes(true) && reqUpdate(vals)

            // //checks to see if the avatar was modified
            newAvatar && postAvatar(avatar)
          }
        }}
        wrapperClass="general"
        initialValues={data}
        renderForm={add}
      >
        <div className="input-section input-section-1">
          <div className="practice-logo-wrapper">
            <PracticeLogo id="practice-logo" name="avatar" img={avatar || data?.avatar || null} />
            <label htmlFor="practice-logo">
              <p className="best-results">For best results logo image should be at least 600px.</p>
              <p className="instructions">Click to Edit Logo</p>
            </label>
          </div>
        </div>
        <div className="input-section input-section-2">
          <div className="input-row input-row-1">
            <Input required={true} label="Practice Name" name="name" />
            <Input label="Practice Website" name="website" />
            {/* <Input required={true} label="Practice Email" name="email" /> */}
          </div>
        </div>
        <div className="input-section input-section-3">
          <div className="input-row input-row-3">
            <Input required={true} label="Practice Address" name="address1" />
            <Input required={true} label="City" />
            <Dropdown
              required={true}
              search
              filterOptions={states => query =>
                states.filter(state =>
                  query?.length > 2
                    ? state?.fullName?.includes?.(query?.toUpperCase?.())
                    : state?.name?.includes?.(query?.toUpperCase?.())
                )}
              label="State"
              options={States.map(s => ({ name: s.abbr, value: s.abbr, fullName: s.name }))}
            />
            <Input required={true} maskedInput={Zip} label="Zipcode" name="zip" />
          </div>
        </div>
        <div className="input-section input-section-4">
          <div className="input-row input-row-4">
            <Input label="Provider Admin Contact Name" name="physicianAdminContactName" />
            <Input label="Provider Admin Contact Phone Number" maskedInput={Phone} name="physicianAdminContactPhone" />
            <Input label="Provider Admin Contact Email" name="physicianAdminContactEmail" />
          </div>
          <div className="input-row input-row-5">
            <Input required={true} maskedInput={Phone} label="Phone Number" />
            {/* <Input maskedInput={Phone} label="Fax Number" name="fax" /> */}
            <div />
            <div className="spacer"></div>
          </div>
        </div>
        {add ? null : (
          <div className="actions">
            <Button
              onClick={e => {
                e.preventDefault()
                closeModal()
              }}
            >
              Cancel
            </Button>
            <Button>Save</Button>
          </div>
        )}
      </ConditionalForm>
    </div>
  )
}
