import { MainContext } from 'context'
import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { StripePaymentContext } from '../context'
import { AlertModal } from 'components'
import { useCart } from 'pages/Patient/Cart/context'

export const useFinalizeStripePayment = () => {
  const { patient, modal } = useContext(MainContext)
  const { data, actions } = useContext(StripePaymentContext)
  const { pathname, state } = useLocation()
  const nav = useNavigate()
  const {
    actions: { resetCart },
  } = useCart()

  const { mutate: finalize, isLoading } = patient.api.finalizeOrder({
    variables: { orderId: data?.id },
    queryOptions: {
      onSuccess: data => {
        if (state?.from === 'cart') resetCart()
        patient.actions.setOrder(data)
        nav(pathname?.replace('payment/checkout', 'approved'), { replace: true })
      },
      onError: () => modal.actions.open(<AlertModal message="There was an error finalizing your payment" />),
    },
  })

  useEffect(() => {
    actions.setIsLoading(isLoading)
  }, [actions, isLoading])

  return { finalize, isLoading }
}
