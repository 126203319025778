import { useCallback, useContext, useEffect } from 'react'
import { StripePaymentContext } from '../context'
import { api } from 'utilities/constants'

export const useDeletePaymentIntent = () => {
  const { paymentIntent, actions } = useContext(StripePaymentContext)

  const deletePaymentIntent = useCallback(async () => {
    if (!paymentIntent?.id || paymentIntent?.status === 'succeeded' || paymentIntent?.status === 'canceled') return

    await fetch(`${api.API_URL}/patientorders/deletepaymentintent/${paymentIntent.id}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
    })
    actions.deletePaymentIntent()
  }, [paymentIntent, actions])

  useEffect(() => {
    deletePaymentIntent()
  }, [deletePaymentIntent])
}

export default useDeletePaymentIntent
