import './StripeDropdown.scss'
import { ChevronRightIcon } from 'assets/vector/icons'
import { Product } from 'pages/Patient/Orders'
import { CostBreakdown } from './CostBreakdown'
import { useContext, useMemo } from 'react'
import { MainContext } from 'context'
import { useLocation } from 'react-router-dom'

export const StripeDropdown = ({ isOpen, close }) => {
  const { patient } = useContext(MainContext)
  const { pathname } = useLocation()

  const data = useMemo(
    () => (pathname.includes('shoppingcart') ? patient.shoppingCart : patient.order),
    [patient, pathname]
  )

  const renderProducts = products => {
    return products?.map((product, i) => {
      return product.removed ? null : (
        <Product isDropdown={true} order={data} closeDropdown={close} key={i} isStripe product={product} />
      )
    })
  }

  return (
    <>
      <div className={`stripe-blur-background ${isOpen && 'apply'}`}></div>
      <div className={`stripe-dropdown-wrap-${isOpen ? 'open' : 'close'}`}>
        <button onClick={close} className="close-stripe-btn">
          <p>Close</p>
          <ChevronRightIcon />
        </button>
        <div className="stripe-items">{renderProducts(data?.LineItems || data?.lineItems || data?.localLineItems)}</div>
        <CostBreakdown isDropdown isPaymentForm data={data} />
      </div>
    </>
  )
}
