import { AlertModal, Button, Loader, ProductsInOrder, PromoMessage, CreatedBy, ReviewOrderInputs } from 'components'
import './ReviewOrder.scss'
import { useCallback, useContext } from 'react'
import { CreateOrderContext } from '../../context'
import { pages } from '../../constants'
import { useSendOrder } from './useSendOrder'
import { MainContext } from 'context'
import Lottie from 'react-lottie-player'
import { sentAirplaneLottieData } from 'assets/lottie'

export const ReviewOrder = () => {
  const { modal } = useContext(MainContext)
  const { actions, patient, products, provider } = useContext(CreateOrderContext)
  const { canSend, success, sendOrder, isLoading } = useSendOrder()
  const isTextEnabled = useTextEnabled()

  const handlePatientSearch = useCallback(
    keyword => {
      actions.setSortListBy({ keyword: keyword?.keyword?.replace(/\\/g, '') || '' })
      actions.setPage(pages.SELECT_PATIENT)
    },
    [actions]
  )

  const handleCancelOrder = useCallback(
    () =>
      modal.actions.open(
        <AlertModal
          message={'Are you sure you want to cancel this order?'}
          denyText={'No'}
          confirmText={'Yes'}
          onConfirm={() => {
            actions.reset()
            modal.actions.close()
          }}
        />
      ),
    [modal, actions]
  )

  const handleEndOfAnimation = useCallback(() => setTimeout(() => actions.reset(), 3000), [actions])

  return (
    <>
      <Loading isLoading={isLoading} />
      <Success success={success} onEnd={handleEndOfAnimation} />
      <div className="create-order-page review-order-wrapper">
        <ReviewOrderInputs
          provider={provider}
          onReplaceProvider={() => actions.setPage(pages.SELECT_PROVIDER)}
          isTextEnabled={isTextEnabled}
          handleSubmit={sendOrder}
          // handleSubmit={val => console.log(val)}
          isValid={canSend}
          isLoading={isLoading}
          showDateInHeader
          handlePatientSearch={handlePatientSearch}
          confirmSendText="Send Order"
          initialValues={{
            patientFirstName: patient?.firstName,
            patientLastName: patient?.lastName,
            patientEmail: patient?.email,
            patientPhone: patient?.cellNumber,
            patientOptIn: patient?.patientOptIn || false,
          }}
        />
        <ProductsInOrder
          onAddMore={() => actions.setPage(pages.SELECT_PRODUCT)}
          onSelect={product => actions.reviewProduct(product)}
          provider={provider}
          products={products}
        />
        <PromoMessage provider={provider} />
        <CreatedBy />
        <div className="review-order-buttons">
          {/* <Button disabled={!canSend} className={'review-order-buttons__send'} onClick={sendOrder}>
            Send Order
          </Button> */}
          <Button className={'review-order-buttons__cancel'} onClick={handleCancelOrder}>
            Cancel Order
          </Button>
        </div>
      </div>
    </>
  )
}

const useTextEnabled = () => {
  const { admin } = useContext(MainContext)
  const { provider } = useContext(CreateOrderContext)

  const { data, isLoading, error } = admin.api.createOrder.isTextEnabled({
    variables: { practiceId: provider?.practiceId },
  })

  return !error && !isLoading && data.isTextEnabled
}

const Loading = ({ isLoading }) =>
  isLoading ? (
    <div className="loader-wrap">
      <Loader fadeEdge={false} />
    </div>
  ) : null

const Success = ({ success, onEnd }) =>
  success ? (
    <div className="create-order__success">
      <Lottie
        className="create-order__success-lottie"
        onAnimationEnd={onEnd}
        loop={false}
        play
        animationData={sentAirplaneLottieData}
      />
      <span>Your order has been sent!</span>
    </div>
  ) : null
