import { cloneElement, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { MainContext } from 'context'
import { useDropzone } from 'react-dropzone'
import { useImmer } from 'use-immer'
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import { AlertModal } from 'components'
import { customQueryKeys, sortByKeys } from 'utilities/constants'
import { DownloadFileIcon, PrinterIcon } from 'assets/vector'
import { getBase64, removeNullValues } from 'utilities/utils'
import { SuccessModal } from 'components/Modals'

export const useWindowWidth = mobileWidth => {
  const [width, setWidth] = useState(window.innerWidth)

  const changeSize = () => setWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', changeSize)
    return () => window.removeEventListener('resize', changeSize)
  }, [])

  return { width, isMobile: width < (mobileWidth || 900) }
}

export const useRouteCheck = () => {
  const { pathname } = useLocation()
  return pathname.includes('practice') ? 'practice-admin' : pathname.includes('admin') ? 'admin' : 'patient'
}

/**
 * @param {Boolean} isOpen if you want to show the messageBubble
 * @param {String|null} [custom = null] custom message to be set in the message bubble
 */
export const useBubbleProps = ({ custom = null, isOpen = true }) => {
  const { patient } = useContext(MainContext)
  const { setBubbleProps, setBubbleOpen } = patient?.actions

  const setProps = useCallback(() => {
    setBubbleProps({ ...custom })
  }, [setBubbleProps, custom])

  useEffect(() => {
    if (!isOpen) {
      setBubbleOpen(false)
    } else {
      setBubbleOpen(true)
      setProps()
    }
  }, [isOpen, setBubbleOpen, setBubbleProps, setProps, custom])
}

/**
 * @param {{onNewFiles: Function, dropzoneOptions: import('react-dropzone').DropzoneOptions }} props
 * @returns {[dropZone, {files: Array<{preview: string, data: string }> | {preview: string, data: string },error: boolean, setFiles: setFiles, setError: setError}]} [DropzoneState, Getters and Setters]
 */
export const useImageDropzone = ({ onNewFiles, dropzoneOptions }, useBlob = false) => {
  const [files, setFiles] = useState(dropzoneOptions?.maxFiles > 1 ? [] : null)
  const [error, setError] = useState(null)

  const onDropAccepted = useCallback(
    async acceptedFiles => {
      setError(false)
      const parsedFiles = await Promise.all(
        acceptedFiles.map(async file => {
          const preview = URL.createObjectURL(file)
          return { preview, data: useBlob ? file : await getBase64(file) }
        })
      )
      dropzoneOptions?.maxFiles > 1 ? setFiles(parsedFiles) : setFiles(parsedFiles?.[0])
    },
    [dropzoneOptions?.maxFiles, useBlob]
  )

  useEffect(() => {
    dropzoneOptions?.maxFiles !== 1 ? onNewFiles(files) : onNewFiles(files?.[0])
  }, [dropzoneOptions?.maxFiles, files, onNewFiles])

  useEffect(() => () => files?.map?.(file => URL.revokeObjectURL(file?.preview)), [files])

  const dropZone = useDropzone({
    onDropAccepted,
    onDropRejected: e => {
      console.log(e)
      setError(e?.[0]?.errors?.[0]?.message)
    },
    multiple: dropzoneOptions?.maxFiles > 1,
    maxFiles: dropzoneOptions?.maxFiles || 1,
    accept: { 'image/*': ['.jpeg', '.png', '.gif', '.jpg'] },
    maxSize: 5242880,
    ...dropzoneOptions,
  })

  return useMemo(() => [dropZone, { files, setFiles, error, setError }], [dropZone, error, files])
}

/**
 * @param {[String]} sources image sources in order from lowest resolution to highest
 * @param {String} [fallback] (optional) fallback image source if all others fail.
 * @returns {String} the highest resolution image source that has loaded.
 */
export const useProgressiveImage = (sources, fallback) => {
  const [srcLoaded, setSrcLoaded] = useImmer(new Array(sources?.length))
  const [highestRes, setHighestRes] = useState({ src: null, i: -1 })

  useEffect(() => {
    sources?.forEach?.((src, i) => {
      const img = new Image()
      img.src = src
      img.onload = () => setSrcLoaded(draft => void (draft[i] = src))
      return
    })
  }, [setSrcLoaded, sources])

  useEffect(() => {
    setHighestRes(highestRes => srcLoaded.reduce((a, c, i) => (a.i < i && !!c ? { src: c, i } : a), highestRes))
  }, [setHighestRes, srcLoaded])

  return highestRes?.src || fallback || null
}

export const useSvgButton = svg => (
  <button
    {...svg.props}
    onClick={e => {
      e.preventDefault()
      svg?.props?.onClick?.(e)
    }}
    role={undefined}
    style={{
      border: 'none',
      backgroundColor: 'transparent',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      margin: 0,
      ...svg?.props?.style,
    }}
    {...{ 'aria-label': undefined }}
  >
    {cloneElement(svg, {
      className: svg.props.className ? `${svg.props.className}--img` : '',
      role: 'img',
      pointerEvents: 'none',
    })}
  </button>
)

/////
export const useUpdate = ({ dataShape, errorMessage }) => {
  const {
    modal,
    admin,
    admin: {
      practices: { one },
    },
  } = useContext(MainContext)
  const queryClient = useQueryClient()
  const [invalidCardNum, setInvalidCardNum] = useState(false)

  const {
    mutate: update,
    isLoading,
    data,
  } = admin.api.practice.update({
    queryOptions: {
      onSuccess: () => {
        modal?.actions?.open(<SuccessModal title="Practice updated successfully." />)
        queryClient.invalidateQueries(one.id)
      },
      onError: error => {
        if (error.toString() === 'Error: Your card number is incorrect.') setInvalidCardNum(true)
        else {
          modal?.actions.open(<AlertModal message={error?.description || errorMessage || 'There was an error'} />)
        }
      },
    },
  })

  const reqUpdate = async values => {
    let body = dataShape(values, one)
    await update({ variables: { id: one.id }, body })
  }

  return { reqUpdate, isLoading, data, invalidCardNum }
}

//
export const useParamsToObject = () =>
  Array.from(useSearchParams()?.[0]?.entries?.())?.reduce?.((a, c) => ({ ...a, [c[0]]: c[1] }), {})
///

///
export const useUserPagination = ({ apiFn, variables = null, params, keys }) => {
  const qc = useQueryClient()
  const [page, setPage] = useState(params?.page || 1)
  const [totalPages, setTotalPages] = useState(params?.totalPages || 0)
  const [sortBy, setSortBy] = useState({ id: null, desc: false })
  const [searchText, setSearch] = useState(params?.searchText || null)

  const search = v => (v?.query ? setSearch(v?.query) : setSearch(null))

  const { data, isLoading, error, refetch } = apiFn({
    variables,
    params: removeNullValues({
      ...params,
      page,
      sortColumn: sortBy?.id ?? sortByKeys.last,
      sortAscDesc: sortBy?.desc ? 'DESC' : 'ASC',
      searchText,
    }),
    queryOptions: {
      onSuccess: data => setTotalPages(data?.totalPages || data?.pageCount),
      staleTime: 0,
    },
    keys,
  })

  useEffect(() => () => qc.cancelQueries([...keys]), [qc, keys])

  return { refetch, data, isLoading, error, totalPages, page, setPage, tableSort: setSortBy, search }
}

/////

export const useFees = (add = false, queryOptions = {}) => {
  const { admin, modal } = useContext(MainContext)
  const queryClient = useQueryClient()

  const success = title => () => {
    queryClient.invalidateQueries(['practices', 'practiceId', 'fees', +admin.practices.one.id])
    modal?.actions?.open(<SuccessModal title={title} />)
  }

  const { mutate, isLoading: editLoading } = admin.api.practice.updateFees({
    variables: { practiceId: admin.practices.one.id },
    queryOptions: {
      onSuccess: success('Fees have been updated!'),
      onError: () => modal?.actions?.open(<AlertModal message={'Could not update fees'} />),
      ...queryOptions,
    },
  })

  const { data: fees, isLoading } = admin.api.practice.getFees({
    variables: { practiceId: admin.practices.one.id },
    queryOptions: {
      enabled: !add,
    },
    lazy: !!add,
    keys: [customQueryKeys.PRACTICE_DETAILS],
  })

  const editFees = v => {
    mutate({
      body: {
        enrollmentDate: v?.enrollmentDate ?? fees?.enrollmenteDate,
        textEmailFee: +v?.textEmailFee,
        medBotPlacedFee: +v?.medBotPlacedFee,
        medMinningBackfillFee: +v?.medMinningBackfillFee,
        transactionFee: +v?.transactionFee,
        transactionFeePercentage: v?.transactionFeePercentage,
        refundProcessingFee: +v?.refundProcessingFee,
        monthlyFees: [
          {
            id: fees?.fees?.[0].id,
            startDate: v?.startDate,
            endDate: v?.endDate,
            amount: +v?.feeAmount,
          },
        ],
      },
    })
  }

  return { isLoading: isLoading || editLoading, fees, editFees }
}
//////
///

///
export const useAdminStatus = () => {
  const { admin } = useContext(MainContext)
  const [isAdmin, setIsAdmin] = useState(true)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    admin?.user?.role?.includes('admin') ? setIsAdmin(true) : setIsAdmin(false)
    setIsLoading(false)
  }, [admin?.user?.role, setIsLoading])

  return { isAdmin, isPracticeUser: !isAdmin ?? false, isLoading }
}

//////////
export const usePracticeAvatar = ({ onSuccess, onError, practiceId, keys = [] }) => {
  const { admin } = useContext(MainContext)

  const { mutate, isLoading: postLoading } = admin.api.practice.postAvatar({
    fetchProps: {
      headers: {
        Accept: 'application/json',
      },
    },
    queryOptions: { onSuccess, onError },
  })

  const postAvatar = (avatarFile, id = null) => {
    const data = new FormData()
    data.append('avatar', avatarFile, avatarFile?.name)
    mutate({ variables: { id: id || admin.practices.one.id }, body: data })
  }

  const { data, refetch: getAvatar } = admin.api.practice.getAvatar({
    variables: { practiceId },
    queryOptions: {
      enabled: practiceId ? true : false,
    },
    keys,
  })

  return {
    postAvatar,
    postLoading,
    avatar: data instanceof Blob ? URL.createObjectURL(data) : data === 'success' ? null : data,
    getAvatar,
  }
}
////////////

////////////
export const useModals = () => {
  const { modal } = useContext(MainContext)
  const qc = useQueryClient()

  return {
    openSuccessModal: msg => modal.actions.open(<SuccessModal message={msg} />),
    success: (msg, queryKey) => () => {
      modal.actions.open(<SuccessModal title={msg} />)
      queryKey && qc.invalidateQueries(queryKey)
    },
    openErrorModal: msg => modal?.actions?.open(<AlertModal message={msg} />),
    error: msg => () => modal?.actions?.open(<AlertModal message={msg} />),
    invQueries: keys => qc.invalidateQueries(keys),
  }
}
/////
/////

///////////

export const useDebounce = (value, delay) => {
  const [val, setVal] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => setVal(value), delay)

    return () => clearTimeout(handler)
  }, [value, delay])

  return val
}

export const useOutsideClick = ({ ref, customAction }) => {
  useEffect(() => {
    const handleOutsideClick = event => {
      if (ref?.current && !ref?.current?.contains(event.target)) {
        customAction()
      }
    }
    document.addEventListener('mousedown', handleOutsideClick)
    return () => document.removeEventListener('mousedown', handleOutsideClick)
  }, [ref, customAction])
}

/* ******************************** */

export const useLongPress = (callback = () => {}, delay = 300) => {
  const [startLongPress, setStartLongPress] = useState(false)

  useEffect(() => {
    let timerId
    if (startLongPress) {
      timerId = setTimeout(callback, delay)
    } else {
      clearTimeout(timerId)
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [callback, delay, startLongPress])

  const start = useCallback(() => {
    setStartLongPress(true)
  }, [])

  const stop = useCallback(() => {
    setStartLongPress(false)
  }, [])

  return {
    onClick: callback,
    onMouseDown: start,
    onMouseUp: stop,
    onMouseLeave: stop,
    onTouchStart: start,
    onTouchEnd: stop,
  }
}

/* ******************************** */

export const usePrint = ({ printRef, title }) => {
  const [ToPrint, setReactToPrint] = useState(null)

  const importModule = useCallback(async () => {
    if (ToPrint) return
    const toPrint = await import('https://cdn.jsdelivr.net/npm/react-to-print/+esm')
    setReactToPrint(toPrint.default)
  }, [ToPrint])

  const ReactToPrint = useMemo(() => (ToPrint ? ToPrint.default : null), [ToPrint])

  return {
    importModule,
    ReactToPrint,
    PrintBtn: useCallback(
      () =>
        ReactToPrint ? (
          <ReactToPrint
            documentTitle={title}
            trigger={() => <PrinterIcon style={{ cursor: 'pointer' }} />}
            content={() => printRef.current}
          />
        ) : (
          <PrinterIcon style={{ cursor: 'pointer' }} onMouseEnter={importModule} />
        ),
      [ReactToPrint, importModule, printRef, title]
    ),
  }
}

/* ******************************** */

export const useDownload = ({ targetRef, filename }) => {
  const [ToPdf, setReactToPdf] = useState(null)

  const importReactToPdf = useCallback(async () => {
    if (ToPdf) return
    const toPdf = await import('https://cdn.jsdelivr.net/npm/react-to-pdf/+esm')
    setReactToPdf(toPdf.default)
  }, [ToPdf])

  let ReactToPdf = useMemo(() => (ToPdf ? ToPdf.default : null), [ToPdf])

  return {
    importReactToPdf,
    ReactToPdf,
    DownloadBtn: useCallback(
      () =>
        ReactToPdf ? (
          <ReactToPdf filename={filename} targetRef={targetRef} x={0.5} y={0.5}>
            {({ toPdf }) => <DownloadFileIcon style={{ cursor: 'pointer' }} onClick={toPdf} />}
          </ReactToPdf>
        ) : (
          <DownloadFileIcon style={{ cursor: 'pointer' }} onMouseEnter={importReactToPdf} />
        ),
      [importReactToPdf, filename, ReactToPdf, targetRef]
    ),
  }
}

/**
 * @param {number} interval interval in milliseconds
 * @param {boolean} trigger whether to start the timer
 * @returns {number} elapsed number of intervals, starting at 0.
 * @example
 * const elapsed = useTimekeeper(1000, true)
 * // elapsed will be 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, ...
 * // every second
 */
export const useTimekeeper = (interval = 1000, trigger = false) => {
  const [elapsed, setTime] = useState(0)

  useEffect(() => {
    if (!trigger) return
    const timer = setInterval(() => setTime(elapsed => elapsed + 1), interval)
    return () => clearInterval(timer)
  }, [elapsed, interval, trigger])

  return elapsed
}
/**
 *
 * @param {string[]} loadingMessages array of messages to display
 * @param {boolean} isLoading is the component loading
 * @param {number} interval interval in seconds
 * @param {string} fallback fallback message
 * @returns {string} message to display
 * @example
 * const message = useLoadingMessage(['Loading', 'Still Loading', 'Almost There'], true, 6, 'Loading')
 * // message will be 'Loading', 'Still Loading', 'Almost There', 'Almost There', 'Almost There', 'Almost There', ...
 * // every 6 seconds
 */
export const useLoadingMessage = (loadingMessages, isLoading, interval = 6, fallback) => {
  const loadTime = useTimekeeper(1000, isLoading)

  return useMemo(() => {
    if (isLoading && !(loadTime === undefined || loadTime === null)) {
      let intervalInt

      if (interval <= 0) intervalInt = 6
      else intervalInt = Math.floor(interval)

      const currentInterval = Math.floor((loadTime ?? intervalInt) / intervalInt),
        isInBounds = currentInterval < loadingMessages.length,
        index = isInBounds ? currentInterval : loadingMessages.length - 1 || 0

      return loadingMessages[index]
    }

    return fallback
  }, [fallback, interval, isLoading, loadingMessages, loadTime])
}

/**
 *
 * @returns {function(pathname):void}
 * @example
 * const nav = useNavWithParams()
 *  nav('Nav/to/path')
 */
export const useNavWithParams = () => {
  const nav = useNavigate()
  const params = useParamsToObject()

  return pathname => nav({ pathname, search: createSearchParams(params).toString() })
}

export const useDynamicImage = fileName => {
  const [image, setImage] = useState(null)

  useEffect(() => {
    import(`../assets/raster/${fileName}`).then(response => {
      setImage(response.default)
    })
  }, [fileName])

  return image
}
