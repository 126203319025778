import { Button, Input, CloseButton, Loader } from 'components/Global'
import { MainContext } from 'context'
import { useContext } from 'react'
import { Form } from 'react-final-form'
import { SuccessModal, AlertModal } from 'components/Modals'
import './ForgotPassword.scss'
import { useParamsToObject } from 'utilities/hooks'

export const ForgotPassword = ({ closeModal, resendInvite = null, resendLoading, onSubmitOverride = null }) => {
  const { email } = useParamsToObject()
  const { admin, modal } = useContext(MainContext)
  const { mutate: sendEmail, isLoading } = admin.api.password.sendResetPassword({
    queryOptions: {
      onSuccess: () => modal.actions.open(<SuccessModal title="Recovery email has been sent" />),
      onError: () => modal.actions.open(<AlertModal message="Could not send email" />),
    },
  })

  if (isLoading || resendLoading) return <Loader />

  return (
    <div className="modal-content forgot-password-modal-wrapper">
      {!resendInvite && <CloseButton className="close-btn" onClick={closeModal} />}
      <span className="forgot-password-modal_header">{resendInvite ? 'Resend Invite' : 'Forgot Password'}</span>
      <Form
        onSubmit={({ email }) =>
          onSubmitOverride
            ? onSubmitOverride()
            : resendInvite
            ? resendInvite({ variables: { email } })
            : sendEmail({ body: { email } })
        }
        initialValues={{ email }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className="forgot-password-modal_form">
            <Input className="forgot-password-modal_input-email" label="Email" name="email" required={true} />
            <div className="actions">
              {!resendInvite && (
                <Button onClick={closeModal} className="button-cancel">
                  Cancel
                </Button>
              )}
              <Button type="submit" className="button-send">
                {resendInvite ? 'Resend Invite' : 'Send Recovery Email'}
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  )
}
