import { useCallback } from 'react'
import { LittleXCircleIcon } from '../../../assets/vector'
import './Notification.scss'
import { useCart } from 'pages/Patient/Cart/context'

export const Notification = ({ className }) => {
  const { actions, notification } = useCart()

  const closeNotify = useCallback(() => actions?.notify(null), [actions])

  if (!notification) return null

  return (
    <div className={`notification-wrap ${notification ? 'notify' : ''}`} onAnimationEnd={closeNotify}>
      <span>{notification}</span>
      <button onClick={closeNotify} className="close-notification">
        <LittleXCircleIcon />
      </button>
    </div>
  )
}
