import { useCallback, useContext } from 'react'
import { MedBotList } from 'components/AlphabeticList'
import { CreateOrderContext } from '../../context'
import { pages } from '../../constants'

export const SelectMedbot = () => {
  const { actions } = useContext(CreateOrderContext)

  const handleClick = useCallback(
    medbot => {
      /// we have to join the medBotProduct and lineItems
      const { medBotProducts, lineItems, ...restOfMedbot } = medbot
      const merged = []
      for (const item of lineItems) {
        let i = medBotProducts.find(p => p.productName === item.productName)
        merged.push({ ...item, ...i })
      }
      ///set products to merged array and set medbot
      actions.setPage(pages.SELECT_PROVIDER)
      actions.setProducts(merged)
      actions.setMedbot(restOfMedbot)
    },
    [actions]
  )

  return <MedBotList onSelectMedbot={handleClick} />
}

/* <div className="create-order-page__list-header select-medbot-header">
        <div className="select-medbot-header__name">
          <button onClick={() => actions.setSortListBy({ asc: !sortListBy.asc, header: 'Medbot Name' })}>
            Medbot Name
            <UpAndDownArrowsIcon
              className={`sort-arrows ${sortListBy?.header === 'Medbot Name' ? 'on' : 'off'} ${
                sortListBy.asc ? 'asc' : 'desc'
              }`}
            />
          </button>
        </div>
        <div className="select-medbot-header__products">
          <span>Products</span>
        </div>
      </div> 
      --------------------------

      // export const SelectMedbot = ({ onSelectMedbot }) => {
//   const [sortListBy, setSortListBy] = useState({ letter: null, keyword: '', asc: false, header: null })
//   const { data, isLoading, error } = useGetMedbots(sortListBy)

//   ///onSearch
//   const search = keyword => setSortListBy({ keyword: keyword?.keyword?.replace(/\\/g, '') || '' })

//   const mapItems = useCallback(() => {
//     let prevMedbot = null
//     return isLoading ? (
//       <Loader />
//     ) : error ? (
//       <p className="create-order-page__alphabetic-list--error-msg">There was an error</p>
//     ) : (
//       data?.map((medbot, j) => {
//         let addTab = setMarker(prevMedbot, medbot.medbotName)
//         prevMedbot = medbot.medbotName
//         return <MedbotItem onSelectMedbot={onSelectMedbot} key={j} addTab={addTab} medbot={medbot} />
//       })
//     )
//   }, [data, isLoading, error, onSelectMedbot])

//   return (
//     <div className="create-order-page select-medbot-wrapper">
//       <Search onSubmit={search} name={'keyword'} placeholder={'Search Medbot Products'} />
//       <AlphaSearch setSortListBy={setSortListBy} sortListBy={sortListBy} />
//       <AlphaListHeader
//         sortListBy={sortListBy}
//         className={'select-medbot'}
//         columns={[
//           {
//             header: 'Medbot Name',
//             onClick: () => setSortListBy({ asc: !sortListBy.asc, header: 'Medbot Name' }),
//             sortable: true,
//           },
//           { header: 'Products', sortable: false },
//         ]}
//       />
//       <ol className="create-order-page__alphabetic-list select-medbot-list">{mapItems()}</ol>
//     </div>
//   )
// }
      
      
      */
