import { AlertModal } from 'components'
import { MainContext } from 'context'
import { useCallback, useContext } from 'react'
import { ProductViewContext } from './context'

export const useProductControls = () => {
  const { modal } = useContext(MainContext)
  const { actions, product, isInOrder, allowQtyControls } = useContext(ProductViewContext)

  const handleQuantityToggle = useCallback(
    add => () => {
      let q
      if (add) {
        q = product?.quantity ? product.quantity + 1 : 2
      } else {
        q = product?.quantity ? product?.quantity - 1 : 1
      }

      actions.updateProduct({ quantity: q })
    },
    [actions, product]
  )

  const handleDelete = useCallback(
    () =>
      modal.actions.open(
        <AlertModal
          message="Are you sure you want to remove this product from your order?"
          denyText={'No'}
          confirmText={'Yes'}
          onConfirm={() => {
            actions.onDelete(product)
            modal.actions.close()
          }}
        />
      ),
    [actions, product, modal?.actions]
  )

  const onChangeRefillFrequency = useCallback(
    newFrequency => actions.updateProduct({ refillFrequency: newFrequency }),
    [actions]
  )

  return {
    onChangeRefillFrequency,
    handleMinus: handleQuantityToggle(false),
    handleAdd: handleQuantityToggle(true),
    handleDelete: isInOrder ? handleDelete : null,
    showQtyControls: allowQtyControls, // will hide or show qty toggle and delete button
  }
}
