import { ShoppingCartIcon } from 'assets/vector'
import { MainContext } from 'context'
import { useCart } from 'pages/Patient/Cart/context'
import { useContext, useCallback, useMemo, useEffect, useState } from 'react'
import './ShoppingCart.scss'
import { useNavWithState } from 'utilities/Patient'

export const ShoppingCart = () => {
  const { navWithState } = useNavWithState()
  const {
    patient: { viewProduct, actions },
  } = useContext(MainContext)
  const { LineItems, showIcon } = useCart()
  const [animate, setAnimate] = useState(false)

  const handleCartClick = useCallback(() => {
    if (viewProduct) actions.viewProduct(null)
    navWithState('/cart')
  }, [navWithState, viewProduct, actions])

  const itemsInCart = useMemo(() => LineItems?.length || 0, [LineItems])

  useEffect(() => {
    setAnimate(true)
  }, [itemsInCart])

  return showIcon ? (
    <div className="main-header__shopping-cart-wrap">
      <button type="button" onClick={handleCartClick} className="main-header__shopping-cart-button">
        <ShoppingCartIcon />
        {itemsInCart > 0 && (
          <span
            className={`main-header__shopping-cart--item-count ${animate ? 'update-count' : ''}`}
            onAnimationEnd={() => setAnimate(false)}
          >
            {itemsInCart}
          </span>
        )}
      </button>
    </div>
  ) : null
}
