import { ProductView } from 'components'
import { useContext } from 'react'
import { MedBotsContext } from '../context'
import { useNavigate, useParams } from 'react-router-dom'
import { PageHeader } from './PageHeader'

export const ProductDetails = () => {
  const nav = useNavigate()
  const { productId } = useParams()
  const { actions, lineItems, medbotId } = useContext(MedBotsContext)

  return (
    <>
      <PageHeader title={'View Product'} onBack={() => nav(-1)} />
      <ProductView
        allowQtyControls={!!!medbotId}
        isInOrder
        onDelete={product => {
          actions.deleteProduct(product.productId)
          nav(-1)
        }}
        onUpdate={product => {
          actions.updateProduct(product)
          nav(-1)
        }}
        product={lineItems.find(item => +item.productId === +productId)}
      />
    </>
  )
}
