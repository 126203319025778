import { MainContext } from 'context'
import { useContext } from 'react'
import { AlertModal } from 'components'
import { useNavWithState } from 'utilities/Patient'

export const useUpdateOrder = ({ orderId }) => {
  const { patient, modal } = useContext(MainContext)
  const { navWithState, location } = useNavWithState()

  const { mutate: updateOrder, isLoading } = patient.api.updateOrder({
    variables: { orderId },
    queryOptions: {
      onSuccess: d => {
        patient.actions.setOrder({ ...patient?.order, ...d })
      },
      onError: () => {
        modal.actions.open(
          <AlertModal
            message="There was an error updating your order."
            onConfirm={() => {
              modal.actions.close()
              if (location?.state?.from) navWithState(-1)
              else navWithState('/')
            }}
          />
        )
      },
    },
  })

  return { updateOrder, isLoading }
}

/*
DEPRECATED DO NOT USE

export const useCreateOrder = () => {
  const nav = useNavigate()
  const { patient, modal } = useContext(MainContext)
  const { shoppingCart, actions } = patient

  const { mutate: createDraft, isLoading } = patient.api.createFromShoppingCart({
    variables: {
      practiceId: shoppingCart?.practiceId || shoppingCart?.LineItems?.[0]?.practiceId,
    },
    queryOptions: {
      onSuccess: d => actions.setCart(d),
      onError: () => {
        modal.actions.open(
          <AlertModal
            message="There was an error creating your order."
            onConfirm={() => {
              modal.actions.close()
              nav(-1)
            }}
          />
        )
      },
    },
  })

  return { createDraft, isLoading }
}
*/
