import { useStripe, useElements } from '@stripe/react-stripe-js'
import { AlertModal } from 'components'
import { MainContext } from 'context'
import { useCallback, useContext, useEffect, useState } from 'react'
import { StripePaymentContext } from '../context'
import { useFinalizeStripePayment } from './useFinalizeStripePayment'

export const useStripeIntegration = () => {
  const [loading, setLoading] = useState(false)
  const { modal } = useContext(MainContext)
  const stripe = useStripe()
  const elements = useElements()
  const {
    actions,
    shippingAddress,
    data: { id: orderId },
  } = useContext(StripePaymentContext)

  const { finalize, isLoading } = useFinalizeStripePayment()

  useEffect(() => {
    if (stripe) actions.setStripeInstance(stripe)
  }, [stripe, actions])

  /*
  This is the actual call to pay through stripe
  */
  const handleStripeSubmit = useCallback(
    async e => {
      e.preventDefault()
      if (!stripe || !elements) return
      setLoading(true)

      const { error, ...rest } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window?.location?.protocol}//${window?.location?.host}/order/` + orderId,
        },
        redirect: 'if_required',
      })

      if (!!error?.type && error.type !== 'validation_error') {
        modal.actions.open(<AlertModal message={'There was an error processing your payment. Please try again.'} />)
      }

      /*
    If there are no errors, we can find the status of the payment inside the paymentIntent
    */
      if (rest?.paymentIntent?.status === 'succeeded') {
        finalize({
          body: {
            shippingAddress,
            paymentId: rest.paymentIntent.id,
            paymentType: 'card',
            patientToken: null,
          },
        })
      }

      setLoading(false)
    },
    [stripe, elements, orderId, modal?.actions, finalize, shippingAddress]
  )

  return { handleStripeSubmit, stripe, elements, isLoading: isLoading || loading }
}
