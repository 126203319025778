import { createContext, useMemo } from 'react'
import StripePaymentReducer, { initStripePayment, useStripePaymentActions } from './StripePaymentReducer'
import { useImmerReducer } from 'use-immer'

export const StripePaymentContext = createContext(initStripePayment)

export const StripePaymentProvider = ({ children, data, isLoading }) => {
  const [state, dispatch] = useImmerReducer(StripePaymentReducer, initStripePayment)
  const actions = useStripePaymentActions(dispatch)

  const value = useMemo(
    () => ({ ...state, data, isLoading: isLoading || state.isLoading, actions }),
    [state, data, isLoading, actions]
  )

  return <StripePaymentContext.Provider value={value}>{children}</StripePaymentContext.Provider>
}
