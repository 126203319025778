import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import { MainProvider } from './MainContext'

const GlobalProvider = ({ children }) => {
  const client = new QueryClient()
  return (
    <QueryClientProvider client={client}>
      <MainProvider>
        <BrowserRouter>{children}</BrowserRouter>
      </MainProvider>
      <ReactQueryDevtools position="right" initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default GlobalProvider
