import './Refund.scss'
import { useContext, useState } from 'react'
import { Reasons } from 'pages'
import { AlertModal } from '../Alert'
import { MainContext } from 'context'
import { Spinner } from 'pages/Patient/Spinner'
import { useModals } from 'utilities/hooks'
import { useQueryClient } from 'react-query'
import { customQueryKeys } from 'utilities/constants'

const keys = [customQueryKeys.ORDER_DETAILS]

const reasons = [
  'Defective Product',
  'Ordered by Mistake',
  'Item did not arrive',
  'Wrong Item',
  'Inaccurate Description',
  'Other',
]

export const RefundModal = ({ product, isAdmin, closeModal }) => {
  const qc = useQueryClient()
  const { orderId, productId } = product
  const { patient } = useContext(MainContext)
  const { openErrorModal, openSuccessModal } = useModals()
  /* These need to get passed to backend */
  const [reason, selectReason] = useState('')
  const [other, setOther] = useState('')
  const [err, setErr] = useState(false)

  const onError = () => openErrorModal('There was an error refunding your product.')
  const onSuccess = () => {
    openSuccessModal(
      'Please allow for 5 to 10 business days for the refund process to be completed. Thank you for your patience'
    )
    qc.invalidateQueries(keys)
  }
  /* the api call is in the patient reducer but works for both portals */
  const { mutate: initRefund, isLoading } = patient.api.refundProduct({
    variables: { orderId },
    queryOptions: {
      onError,
      onSuccess,
    },
  })

  const onSubmit = () =>
    reason ? initRefund({ body: { productId, reason: `${reason}${other ? ' - ' + other : ''}` } }) : setErr(true)

  if (isLoading) return <Spinner />

  return (
    <AlertModal
      message="Reason for refund?"
      onConfirm={onSubmit}
      onDeny={closeModal}
      denyText={'Back'}
      buttonText={'Send'}
      refundProduct
      isAdmin={isAdmin}
    >
      <Reasons selected={reason} reasons={[...reasons]} setOther={setOther} passReasonsUp={selectReason} />
      {err && <span className="refund-error__msg">You must choose a reason for the refund.</span>}
    </AlertModal>
  )
}
