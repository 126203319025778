import { HamburderMenuXIcon } from 'assets/vector'
import { AlertModal, Button, Input } from 'components'
import { MainContext } from 'context'
import { Spinner } from 'pages/Patient/Spinner'
import { useCallback, useContext, useState } from 'react'
import { Form } from 'react-final-form'
import { svgButton } from 'utilities/utils'

export const Forgot = ({ close }) => {
  const { patient, modal } = useContext(MainContext)
  const [sent, setSent] = useState(false)

  const { mutate: send, isLoading } = patient.api.sendResetPassword({
    queryOptions: {
      onSuccess: () => setSent(true),
      onError: () => modal.actions.open(<AlertModal message={'There was an error sending the email.'} />),
    },
  })

  const handleFormSubmit = useCallback(values => send({ body: values }), [send])

  return (
    <Form
      onSubmit={handleFormSubmit}
      render={({ handleSubmit }) =>
        isLoading ? (
          <Spinner />
        ) : (
          <form className="patient-login-forgot" onSubmit={handleSubmit}>
            <div className="patient-login-forgot__head"> {svgButton(<HamburderMenuXIcon onClick={close} />)}</div>
            <h4>Forgot Password</h4>
            <Input required name="email" label="Email" />
            <Button onClick={handleSubmit}>Send Recovery Email</Button>
            {sent && (
              <p className="sent-notification">
                An email to reset your password has been sent. Please check your email.
              </p>
            )}
          </form>
        )
      }
    />
  )
}
