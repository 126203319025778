import { ChevronRightIcon } from 'assets/vector'
import { useRef, useState } from 'react'
import { useOutsideClick } from 'utilities/hooks'

const refillFrequencyOptions = [
  'Never',
  '2 weeks',
  '3 weeks',
  '1 month',
  '5 weeks',
  '6 weeks',
  '7 weeks',
  '2 months',
  '3 months',
  '4 months',
  '5 months',
  '6 months',
]

export const AutoshipControls = ({ product, onChangeRefillFrequency }) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef()
  useOutsideClick({ ref, customAction: () => setIsOpen(false) })

  return (
    <div ref={ref} className="product-controls__refills">
      <h5 className="product-controls__refills-label">Deliver every:</h5>
      <button className="product-controls__refills--main-button" onClick={() => setIsOpen(val => !val)}>
        <span className="product-controls__refills--current-value">{product?.refillFrequency || 'Never'}</span>
        <ChevronRightIcon />
      </button>
      {isOpen && (
        <div className="product-controls__refills--dropdown">
          {refillFrequencyOptions.map((option, i) => (
            <button
              key={i}
              onClick={() => {
                onChangeRefillFrequency(option, product)
                setIsOpen(false)
              }}
              className="product-controls__refills--dropdown-option"
            >
              <span>{option}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
