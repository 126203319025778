import { useMemo } from 'react'
import { useHandleBubbleMessage } from '../Orders/ViewOrder/utils'
import { ViewOrder } from '../Orders'
import { useOrder } from './useOrder'
import { CustomLoader } from './CustomLoader'

const showItems = ['draft order', 'aging retail']

export const CurrentOrder = () => {
  const { data, isLoading, error } = useOrder({ cart: false, current: true, history: false })
  useHandleBubbleMessage({ isLoading, error })

  const lineItems = useMemo(() => data?.LineItems || data?.lineItems || data?.localLineItems || null, [data])
  const props = { data, isLoading, error, lineItems }

  if (isLoading) return <CustomLoader />

  return showItems.includes(data?.status) ? <ViewOrder {...props} /> : null
}
